*{box-sizing: border-box;}
.container-out{
    padding-bottom: 80px;
}
.container-x{
    padding-top: 0px;
}
.container{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
.card-one{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    .card-1{
       background-image: url(../../assets/image/x-stay.jpg);
       background-position: center;
       width: 30%;
       height: 450px;
       background-size: cover;
       color: white;
       //padding: 20px;
       border-radius: 12px;
       box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
       .content {
        background: rgba(255, 255, 255, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 10%, rgba(255, 255, 255, .1) 20%, rgba(255, 255, 255, .5) 30%, rgba(255, 255, 255, 1) 70%) repeat scroll 0 0;
        position: relative;
        margin-top: 150px;
        height: 250px ;
        width: 100%;
      }
       
       h2{
        position: relative;
        margin-top: -100px;
        color: #1f6085;;
        font-size: 35px;
        font-weight: 500;
        text-align: center;
       }

    }
    .card-2{
        background-image: url(../../assets/image/purple.JPG);
        background-position: center;
        width: 30%;
        height: 450px;
        background-size: cover;
        color: white;
        //padding: 20px;
       border-radius: 12px;
       box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
       .content {
        background: rgba(255, 255, 255, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 10%, rgba(255, 255, 255, .1) 20%, rgba(255, 255, 255, .5) 30%, rgba(255, 255, 255, 1) 70%) repeat scroll 0 0;
        position: relative;
        margin-top: 150px;
        height: 250px ;
        width: 100%;
      }
       
       h2{
        position: relative;
        margin-top: -100px;
        color: #1f6085;;
        font-size: 35px;
        font-weight: 500;
        text-align: center;
       }
    
     }
     .card-3{
        background-image: url(../../assets/image/ybird.jpg);
        background-position: center;
        width: 30%;
        height: 450px;
        background-size: cover;
        color: white;
        //padding: 20px;
        border-radius: 12px;
        box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
        .content {
            background: rgba(255, 255, 255, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 10%, rgba(255, 255, 255, .1) 20%, rgba(255, 255, 255, .5) 30%, rgba(255, 255, 255, 1) 70%) repeat scroll 0 0;
            position: relative;
            margin-top: 150px;
            height: 250px ;
            width: 100%;
          }
           
           h2{
            position: relative;
            margin-top: -100px;
            color: #1f6085;;
            font-size: 35px;
            font-weight: 500;
            text-align: center;
           }
    
     }
}
}
@media screen and (max-width:390px){
  .container-out{
    padding: 20px !important;
    padding-bottom: 40px !important;
}

.container{
    width: 100% !important;
    .card-one{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        
        .card-1{
           background-image: url(../../assets/image/x-stay.jpg);
           background-position: center;
           width: 31%;
           height: 150px !important;
           background-size: cover;
           color: white;
           //padding: 20px;
           border-radius: 12px;
           box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
           .content {
            background: rgba(255, 255, 255, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 10%, rgba(255, 255, 255, .1) 20%, rgba(255, 255, 255, .5) 30%, rgba(255, 255, 255, 1) 70%) repeat scroll 0 0;
            position: relative;
            margin-top: 70px;
            height: 100px !important;
            width: 100%;
          }
           
           h2{
            position: relative;
            margin-top: -100px;
            color: #1f6085;;
            font-size: 13px;
            font-weight: 500;
            text-align: center;
           }
    
        }
        .card-2{
            background-image: url(../../assets/image/purple.JPG);
            background-position: center;
            width: 31%;
            height: 150px !important;
            background-size: cover;
            color: white;
            //padding: 20px;
            border-radius: 12px;
            box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
            .content {
             background: rgba(255, 255, 255, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 10%, rgba(255, 255, 255, .1) 20%, rgba(255, 255, 255, .5) 30%, rgba(255, 255, 255, 1) 70%) repeat scroll 0 0;
             position: relative;
             margin-top: 70px;
             height: 100px !important;
             width: 100%;
           }
            
            h2{
             position: relative;
             margin-top: -100px;
             color: #1f6085;;
             font-size: 13px;
             font-weight: 500;
             text-align: center;
            }
        
         }
         .card-3{
            background-image: url(../../assets/image/ybird.jpg);
            background-position: center;
            width: 31%;
           height: 150px !important;
           background-size: cover;
           color: white;
           //padding: 20px;
           border-radius: 12px;
           box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
           .content {
            background: rgba(255, 255, 255, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 10%, rgba(255, 255, 255, .1) 20%, rgba(255, 255, 255, .5) 30%, rgba(255, 255, 255, 1) 70%) repeat scroll 0 0;
            position: relative;
            margin-top: 70px;
            height: 100px !important;
            width: 100%;
          }
           
           h2{
            position: relative;
            margin-top: -100px;
            color: #1f6085;;
            font-size: 13px;
            font-weight: 500;
            text-align: center;
           }
        
         }
    }
}

}

@media screen and (max-width:450px){
    .container-out{
        padding: 20px !important;
        padding-bottom: 40px !important;
    }

    .container{
      width: 100%;
        .card-one{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            
            .card-1{
               background-image: url(../../assets/image/x-stay.jpg);
               background-position: center;
               width: 31%;
               height: 150px !important;
               background-size: cover;
               color: white;
               //padding: 20px;
               border-radius: 12px;
               box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
               .content {
                background: rgba(255, 255, 255, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 10%, rgba(255, 255, 255, .1) 20%, rgba(255, 255, 255, .5) 30%, rgba(255, 255, 255, 1) 70%) repeat scroll 0 0;
                position: relative;
                margin-top: 50px !important;
                height: 100px !important;
                width: 100%;
              }
               
               h2{
                position: relative;
                margin-top: -80px !important;
                color: #1f6085;;
                font-size: 13px;
                font-weight: 500;
                text-align: center;
               }
        
            }
            .card-2{
                background-image: url(../../assets/image/purple.JPG);
                background-position: center;
                width: 31%;
                height: 150px !important;
                background-size: cover;
                color: white;
                //padding: 20px;
                border-radius: 12px;
                box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
                .content {
                 background: rgba(255, 255, 255, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 10%, rgba(255, 255, 255, .1) 20%, rgba(255, 255, 255, .5) 30%, rgba(255, 255, 255, 1) 70%) repeat scroll 0 0;
                 position: relative;
                 margin-top: 50px !important;
                 height: 100px !important;
                 width: 100%;
               }
                
                h2{
                 position: relative;
                 margin-top: -80px !important;
                 color: #1f6085;;
                 font-size: 13px;
                 font-weight: 500;
                 text-align: center;
                }
            
             }
             .card-3{
                background-image: url(../../assets/image/ybird.jpg);
                background-position: center;
                width: 31%;
               height: 150px !important;
               background-size: cover;
               color: white;
               //padding: 20px;
               border-radius: 12px;
               box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
               .content {
                background: rgba(255, 255, 255, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 10%, rgba(255, 255, 255, .1) 20%, rgba(255, 255, 255, .5) 30%, rgba(255, 255, 255, 1) 70%) repeat scroll 0 0;
                position: relative;
                margin-top: 50px !important;
                height: 100px !important;
                width: 100%;
              }
               
               h2{
                position: relative;
                margin-top: -80px !important;
                color: #1f6085;;
                font-size: 13px;
                font-weight: 500;
                text-align: center;
               }
            
             }
        }
    }


  }



  @media screen and (min-width: 700px) and (max-width: 950px){
    .container-out{
        padding: 20px !important;
        padding-bottom: 40px !important;
    }

    .container{
      width: 100%;
        .card-one{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            
            .card-1{
               background-image: url(../../assets/image/x-stay.jpg);
               background-position: center;
               width: 31%;
               height: 270px;
               background-size: cover;
               color: white;
               //padding: 20px;
               border-radius: 12px;
               box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
               .content {
                background: rgba(255, 255, 255, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 10%, rgba(255, 255, 255, .1) 20%, rgba(255, 255, 255, .5) 30%, rgba(255, 255, 255, 1) 70%) repeat scroll 0 0;
                position: relative;
                margin-top: 60px;
                height: 220px ;
                width: 100%;
              }
               
               h2{
                position: relative;
                margin-top: -100px;
                color: #1f6085;;
                font-size: 20px;
                font-weight: 500;
                text-align: center;
               }
        
            }
            .card-2{
                background-image: url(../../assets/image/purple.JPG);
                background-position: center;
                width: 31%;
                height: 270px;
                background-size: cover;
                color: white;
                //padding: 20px;
                border-radius: 12px;
                box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
                .content {
                 background: rgba(255, 255, 255, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 10%, rgba(255, 255, 255, .1) 20%, rgba(255, 255, 255, .5) 30%, rgba(255, 255, 255, 1) 70%) repeat scroll 0 0;
                 position: relative;
                 margin-top: 60px;
                 height: 220px ;
                 width: 100%;
               }
                
                h2{
                 position: relative;
                 margin-top: -100px;
                 color: #1f6085;;
                 font-size: 20px;
                 font-weight: 500;
                 text-align: center;
                }
            
             }
             .card-3{
                background-image: url(../../assets/image/ybird.jpg);
                background-position: center;
                width: 31%;
               height: 270px;
               background-size: cover;
               color: white;
               //padding: 20px;
               border-radius: 12px;
               box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
               .content {
                background: rgba(255, 255, 255, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 10%, rgba(255, 255, 255, .1) 20%, rgba(255, 255, 255, .5) 30%, rgba(255, 255, 255, 1) 70%) repeat scroll 0 0;
                position: relative;
                margin-top: 60px;
                height: 220px ;
                width: 100%;
              }
               
               h2{
                position: relative;
                margin-top: -100px;
                color: #1f6085;;
                font-size: 20px;
                font-weight: 500;
                text-align: center;
               }
            
             }
        }
    }


  }
@media screen and (max-width:992px){

    .container{
        max-width: 960px;
    }


  }
    
  

  @media screen and (min-width:1200px){
    .container{
        max-width: 1140px;
    }
   
    }