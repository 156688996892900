*{
    box-sizing: border-box;
}

.booking{
    padding-top: 80px;
    
    
    
.container-c{
 background-color: rgb(239, 238, 238);
 padding: 90px 125px;

 
.container{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    .title{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 40px;
        .part1{
            width: 70%;
            h1{
                font-size: 45px;
                font-weight: 600;
                margin: 0%;
                color: #1f6085;
            }
            p{
                color: gray;
            }
            
        }
        .part2{
            width: 30%;
            
            .book{
                position: relative;
                top: 40px;
                left: 160px;
                height: 40px;
                width: 50%;
                margin-top: 10px;
                border: none;
                background-color:#fff;
                border-radius: 40px;
                font-size: 16px;
                color:#1f6085;
                cursor: pointer;
            }
            .book:hover{
                background-color: #1f6085;
                color: #fff;
            }
        }
    }
    .card-second{
       // display: flex;
       // flex-direction: row;
        //justify-content: space-between;
        // .wrapper{
        //     max-width: 1100px;
        //     width: 100%;
        //     position: relative;
        //     .carousel::-webkit-scrollbar {
        //         display: none;
        //       }
        //       .carousel.no-transition {
        //         scroll-behavior: auto;
        //       }
        //       .carousel.dragging {
        //         scroll-snap-type: none;
        //         scroll-behavior:smooth;
        //       }
        //       .carousel.dragging .card {
        //         cursor: grab;
        //         user-select: none;
        //       }
        //     .carousel{
        //         display: grid;
        //         grid-auto-flow: column;
        //         grid-auto-columns: calc((100% / 2) - 2px);
        //         overflow-x: auto;
        //         scroll-snap-type: x mandatory;
        //         gap: 45px;
        //         border-radius: 8px;
        //         scroll-behavior: smooth;
        //         scrollbar-width: none;
            
              
        //         .card{
        //             scroll-snap-align: start;
        //             height: 31vh;
        //             list-style: none;
        //             background: #fff;
        //             cursor: pointer;
        //             padding-bottom: 15px;
        //             flex-direction: column;
        //             border-radius: 20px;
        //             .first-card{
        //                 display:flex;
        //                 flex-direction: row;
        //                 width: 100%;
        //                 height: 31vh ;
        //                 background-color: #fff;
        //                 justify-content: space-evenly;
        //                 align-items: center;
        //                 border-radius: 20px;
        //                 .c-one{
        //                     width: 190px ;
        //                     height: 190px ;
        //                     background: url(../../assets/image/stays.jpg);
                            
        //                     border-radius: 15px;
        //                 }
        //                 .detail-1{
        //                     width: 50%;
        //                     text-align: center;
        //                     h1{
        //                         font-size: 30px;
        //                         margin-bottom: 10px;

                                
        //                     }
        //                     p{
        //                         line-height: 1.5;
        //                         color: gray;
        //                         margin:  10px;
                                
        //                     }
        //                     .book{
        //                         height: 5vh;
        //                         width: 50%;
        //                         margin-top: 10px;
        //                         border: none;
        //                         background-color:#2779a7;
        //                         border-radius: 40px;
        //                         font-size: 16px;
        //                         color:#fff;
        //                         cursor: pointer;
        //                     }
                            
        //                 }
                        
        //             }
        //         }
        //         .card{
        //             .second-card{
        //                 display:flex;
        //                 flex-direction: row;
        //                 width: 100%;
        //                 height: 31vh;
        //                 background-color: #fff;
        //                 justify-content: space-evenly;
        //                 align-items: center;
        //                 border-radius: 20px;
        //                 .c-one{
        //                     width: 190px ;
        //                     height: 190px ;
        //                     background: url(../../assets/image/Jeep.jpg);
        //                     border-radius: 15px;
        //                 }
        //                 .detail-1{
        //                     width: 50%;
        //                     text-align: center;
        //                     h1{
        //                         font-size: 30px;
        //                         margin-bottom: 10px;
                                
        //                     }
        //                     p{
        //                         line-height: 1.5;
        //                         color: gray;
        //                         margin: 10px ;
        //                     }
        //                     .book{
        //                         height: 5vh;
        //                         width: 50%;
        //                         margin-top: 10px;
        //                         border: none;
        //                         background-color:#2779a7;
        //                         border-radius: 40px;
        //                         font-size: 16px;
        //                         color:#fff;
        //                         cursor: pointer;
        //                     }
                            
        //                 }
                        
        //             }
        //         }
        //         .card{
        //             .third-card{
        //                 display:flex;
        //                 flex-direction: row;
        //                 width: 100%;
        //                 height: 31vh;
        //                 background-color: #fff;
        //                 justify-content: space-evenly;
        //                 align-items: center;
        //                 border-radius: 20px;
        //                 .c-one{
        //                     width: 190px ;
        //                     height: 190px ;
        //                     background: url(../../assets/image/Jeep.jpg);
        //                     border-radius: 15px;
        //                 }
        //                 .detail-1{
        //                     width: 50%;
        //                     text-align: center;
        //                     h1{
        //                         font-size: 30px;
        //                         margin-bottom: 10px;
                                
        //                     }
        //                     p{
        //                         line-height: 1.5;
        //                         color: gray;
        //                         margin: 10px ;
        //                     }
        //                     .book{
        //                         height: 5vh;
        //                         width: 50%;
        //                         margin-top: 10px;
        //                         border: none;
        //                         background-color:#2779a7;
        //                         border-radius: 40px;
        //                         font-size: 16px;
        //                         color:#fff;
        //                         cursor: pointer;
        //                     }
                            
        //                 }
                        
        //             }
        //         }
        //     }
        // }
    }
   }
  }
}


@media screen and (max-width:390px){

    .booking{
        padding-top: 80px;
        
        
        
    .container-c{
     background-color: rgb(239, 238, 238);
     padding:20px !important;
    
     
    .container{
        max-width:100% !important;
    
        .title{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-bottom: 40px;
            .part1{
                width: 70%;
                h1{
                    font-size: 17px;
                    font-weight: 600;
                    margin: 0%;
                    color: #1f6085;
                }
                p{
                    color: gray;
                    font-size: 10px;
                }
                
            }
            .part2{
                width: 30%;
                
                .book{
                    position: relative;
                    top: 0px;
                    left: 20px !important;
                    height: 30px;
                    width: 80%;
                    margin-top: 10px;
                    border: none;
                    background-color:#fff;
                    border-radius: 40px;
                    font-size: 12px !important;
                    color:#144866;
                    cursor: pointer;
                }
                .book:hover{
                    background-color: #144866;
                    color: #fff;
                }
            }
        }
        .card-second{
           // display: flex;
           // flex-direction: row;
            //justify-content: space-between;
            .wrapper{
                max-width: 1100px;
                width: 100%;
                position: relative;
                .carousel::-webkit-scrollbar {
                    display: none;
                  }
                  .carousel.no-transition {
                    scroll-behavior: auto;
                  }
                  .carousel.dragging {
                    scroll-snap-type: none;
                    scroll-behavior:smooth;
                  }
                  .carousel.dragging .card {
                    cursor: grab;
                    user-select: none;
                  }
                .carousel{
                    display: grid;
                    grid-auto-flow: column;
                    grid-auto-columns: calc((100% / 2) - 2px);
                    overflow-x: auto;
                    scroll-snap-type: x mandatory;
                    gap: 45px;
                    border-radius: 8px;
                    scroll-behavior: smooth;
                    scrollbar-width: none;
                
                  
                    .card{
                        scroll-snap-align: start;
                        height: 31vh;
                        list-style: none;
                        background: #fff;
                        cursor: pointer;
                        padding-bottom: 15px;
                        flex-direction: column;
                        border-radius: 20px;
                        .first-card{
                            display:flex;
                            flex-direction: row;
                            width: 100%;
                            height: 31vh ;
                            background-color: #fff;
                            justify-content: space-evenly;
                            align-items: center;
                            border-radius: 20px;
                            .c-one{
                                width: 190px ;
                                height: 190px ;
                                background: url(../../assets/image/stays.jpg);
                                
                                border-radius: 15px;
                            }
                            .detail-1{
                                width: 50%;
                                text-align: center;
                                h1{
                                    font-size: 30px;
                                    margin-bottom: 10px;
    
                                    
                                }
                                p{
                                    line-height: 1.5;
                                    color: gray;
                                    margin:  10px;
                                    
                                }
                                .book{
                                    height: 5vh;
                                    width: 50%;
                                    margin-top: 10px;
                                    border: none;
                                    background-color:#2779a7;
                                    border-radius: 40px;
                                    font-size: 16px;
                                    color:#fff;
                                    cursor: pointer;
                                }
                                
                            }
                            
                        }
                    }
                    .card{
                        .second-card{
                            display:flex;
                            flex-direction: row;
                            width: 100%;
                            height: 31vh;
                            background-color: #fff;
                            justify-content: space-evenly;
                            align-items: center;
                            border-radius: 20px;
                            .c-one{
                                width: 190px ;
                                height: 190px ;
                                background: url(../../assets/image/Jeep.jpg);
                                border-radius: 15px;
                            }
                            .detail-1{
                                width: 50%;
                                text-align: center;
                                h1{
                                    font-size: 30px;
                                    margin-bottom: 10px;
                                    
                                }
                                p{
                                    line-height: 1.5;
                                    color: gray;
                                    margin: 10px ;
                                }
                                .book{
                                    height: 5vh;
                                    width: 50%;
                                    margin-top: 10px;
                                    border: none;
                                    background-color:#2779a7;
                                    border-radius: 40px;
                                    font-size: 16px;
                                    color:#fff;
                                    cursor: pointer;
                                }
                                
                            }
                            
                        }
                    }
                    .card{
                        .third-card{
                            display:flex;
                            flex-direction: row;
                            width: 100%;
                            height: 31vh;
                            background-color: #fff;
                            justify-content: space-evenly;
                            align-items: center;
                            border-radius: 20px;
                            .c-one{
                                width: 190px ;
                                height: 190px ;
                                background: url(../../assets/image/Jeep.jpg);
                                border-radius: 15px;
                            }
                            .detail-1{
                                width: 50%;
                                text-align: center;
                                h1{
                                    font-size: 30px;
                                    margin-bottom: 10px;
                                    
                                }
                                p{
                                    line-height: 1.5;
                                    color: gray;
                                    margin: 10px ;
                                }
                                .book{
                                    height: 5vh;
                                    width: 50%;
                                    margin-top: 10px;
                                    border: none;
                                    background-color:#2779a7;
                                    border-radius: 40px;
                                    font-size: 16px;
                                    color:#fff;
                                    cursor: pointer;
                                }
                                
                            }
                            
                        }
                    }
                }
            }
        }
       }
      }
    }


  }

@media screen and (max-width:450px){

    .booking{
        padding-top: 80px;
        
        
        
    .container-c{
     background-color: rgb(239, 238, 238);
     padding:20px !important;
    
     
    .container{
        max-width:100% !important;
    
        .title{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-bottom: 20px;
            .part1{
                width: 80%;
                h1{
                    font-size: 17px;
                    font-weight: 600;
                    margin: 0%;
                    color: #1f6085;
                }
                p{
                    color: gray;
                    font-size: 12px;
                }
                
            }
            .part2{
                width: 10%;
                
                .book{
                    position: relative;
                    top: 0px;
                    left: 20px !important;
                    height: 30px;
                    width: 80%;
                    margin-top: 10px;
                    border: none;
                    background-color:#fff;
                    border-radius: 40px;
                    font-size: 14px;
                    color:#1f6085;
                    cursor: pointer;
                }
                .book:hover{
                    background-color: #1f6085;
                    color: #fff;
                }
            }
        }
        .card-second{
           // display: flex;
           // flex-direction: row;
            //justify-content: space-between;
            .wrapper{
                max-width: 1100px;
                width: 100%;
                position: relative;
                .carousel::-webkit-scrollbar {
                    display: none;
                  }
                  .carousel.no-transition {
                    scroll-behavior: auto;
                  }
                  .carousel.dragging {
                    scroll-snap-type: none;
                    scroll-behavior:smooth;
                  }
                  .carousel.dragging .card {
                    cursor: grab;
                    user-select: none;
                  }
                .carousel{
                    display: grid;
                    grid-auto-flow: column;
                    grid-auto-columns: calc((100% / 2) - 2px);
                    overflow-x: auto;
                    scroll-snap-type: x mandatory;
                    gap: 45px;
                    border-radius: 8px;
                    scroll-behavior: smooth;
                    scrollbar-width: none;
                
                  
                    .card{
                        scroll-snap-align: start;
                        height: 31vh;
                        list-style: none;
                        background: #fff;
                        cursor: pointer;
                        padding-bottom: 15px;
                        flex-direction: column;
                        border-radius: 20px;
                        .first-card{
                            display:flex;
                            flex-direction: row;
                            width: 100%;
                            height: 31vh ;
                            background-color: #fff;
                            justify-content: space-evenly;
                            align-items: center;
                            border-radius: 20px;
                            .c-one{
                                width: 190px ;
                                height: 190px ;
                                background: url(../../assets/image/stays.jpg);
                                
                                border-radius: 15px;
                            }
                            .detail-1{
                                width: 50%;
                                text-align: center;
                                h1{
                                    font-size: 30px;
                                    margin-bottom: 10px;
    
                                    
                                }
                                p{
                                    line-height: 1.5;
                                    color: gray;
                                    margin:  10px;
                                    
                                }
                                .book{
                                    height: 5vh;
                                    width: 50%;
                                    margin-top: 10px;
                                    border: none;
                                    background-color:#2779a7;
                                    border-radius: 40px;
                                    font-size: 16px;
                                    color:#fff;
                                    cursor: pointer;
                                }
                                
                            }
                            
                        }
                    }
                    .card{
                        .second-card{
                            display:flex;
                            flex-direction: row;
                            width: 100%;
                            height: 31vh;
                            background-color: #fff;
                            justify-content: space-evenly;
                            align-items: center;
                            border-radius: 20px;
                            .c-one{
                                width: 190px ;
                                height: 190px ;
                                background: url(../../assets/image/Jeep.jpg);
                                border-radius: 15px;
                            }
                            .detail-1{
                                width: 50%;
                                text-align: center;
                                h1{
                                    font-size: 30px;
                                    margin-bottom: 10px;
                                    
                                }
                                p{
                                    line-height: 1.5;
                                    color: gray;
                                    margin: 10px ;
                                }
                                .book{
                                    height: 5vh;
                                    width: 50%;
                                    margin-top: 10px;
                                    border: none;
                                    background-color:#2779a7;
                                    border-radius: 40px;
                                    font-size: 16px;
                                    color:#fff;
                                    cursor: pointer;
                                }
                                
                            }
                            
                        }
                    }
                    .card{
                        .third-card{
                            display:flex;
                            flex-direction: row;
                            width: 100%;
                            height: 31vh;
                            background-color: #fff;
                            justify-content: space-evenly;
                            align-items: center;
                            border-radius: 20px;
                            .c-one{
                                width: 190px ;
                                height: 190px ;
                                background: url(../../assets/image/Jeep.jpg);
                                border-radius: 15px;
                            }
                            .detail-1{
                                width: 50%;
                                text-align: center;
                                h1{
                                    font-size: 30px;
                                    margin-bottom: 10px;
                                    
                                }
                                p{
                                    line-height: 1.5;
                                    color: gray;
                                    margin: 10px ;
                                }
                                .book{
                                    height: 5vh;
                                    width: 50%;
                                    margin-top: 10px;
                                    border: none;
                                    background-color:#2779a7;
                                    border-radius: 40px;
                                    font-size: 16px;
                                    color:#fff;
                                    cursor: pointer;
                                }
                                
                            }
                            
                        }
                    }
                }
            }
        }
       }
      }
    }


  }


  @media screen and (min-width: 700px) and (max-width: 950px){

    .booking{
        padding-top: 80px;
        
        
        
    .container-c{
     background-color: rgb(239, 238, 238);
     padding:20px !important;
    
     
    .container{
        max-width:100% !important;
    
        .title{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-bottom: 40px;
            .part1{
                width: 75%;
                h1{
                    font-size: 25px;
                    font-weight: 600;
                    margin: 0%;
                    color: #1f6085;
                }
                p{
                    color: gray;
                    font-size: 12px;
                }
                
            }
            .part2{
                width: 30%;
                
                .book{
                    position: relative;
                    top: 0px;
                    left: 20px !important;
                    height: 30px;
                    width: 80%;
                    margin-top: 10px;
                    border: none;
                    background-color:#fff;
                    border-radius: 40px;
                    font-size: 14px;
                    color:#1f6085;
                    cursor: pointer;
                }
                .book:hover{
                    background-color: #1f6085;
                    color: #fff;
                }
            }
        }
        .card-second{
           // display: flex;
           // flex-direction: row;
            //justify-content: space-between;
            .wrapper{
                max-width: 1100px;
                width: 100%;
                position: relative;
                .carousel::-webkit-scrollbar {
                    display: none;
                  }
                  .carousel.no-transition {
                    scroll-behavior: auto;
                  }
                  .carousel.dragging {
                    scroll-snap-type: none;
                    scroll-behavior:smooth;
                  }
                  .carousel.dragging .card {
                    cursor: grab;
                    user-select: none;
                  }
                .carousel{
                    display: grid;
                    grid-auto-flow: column;
                    grid-auto-columns: calc((100% / 2) - 2px);
                    overflow-x: auto;
                    scroll-snap-type: x mandatory;
                    gap: 45px;
                    border-radius: 8px;
                    scroll-behavior: smooth;
                    scrollbar-width: none;
                
                  
                    .card{
                        scroll-snap-align: start;
                        height: 31vh;
                        list-style: none;
                        background: #fff;
                        cursor: pointer;
                        padding-bottom: 15px;
                        flex-direction: column;
                        border-radius: 20px;
                        .first-card{
                            display:flex;
                            flex-direction: row;
                            width: 100%;
                            height: 31vh ;
                            background-color: #fff;
                            justify-content: space-evenly;
                            align-items: center;
                            border-radius: 20px;
                            .c-one{
                                width: 190px ;
                                height: 190px ;
                                background: url(../../assets/image/stays.jpg);
                                
                                border-radius: 15px;
                            }
                            .detail-1{
                                width: 50%;
                                text-align: center;
                                h1{
                                    font-size: 30px;
                                    margin-bottom: 10px;
    
                                    
                                }
                                p{
                                    line-height: 1.5;
                                    color: gray;
                                    margin:  10px;
                                    
                                }
                                .book{
                                    height: 5vh;
                                    width: 50%;
                                    margin-top: 10px;
                                    border: none;
                                    background-color:#2779a7;
                                    border-radius: 40px;
                                    font-size: 16px;
                                    color:#fff;
                                    cursor: pointer;
                                }
                                
                            }
                            
                        }
                    }
                    .card{
                        .second-card{
                            display:flex;
                            flex-direction: row;
                            width: 100%;
                            height: 31vh;
                            background-color: #fff;
                            justify-content: space-evenly;
                            align-items: center;
                            border-radius: 20px;
                            .c-one{
                                width: 190px ;
                                height: 190px ;
                                background: url(../../assets/image/Jeep.jpg);
                                border-radius: 15px;
                            }
                            .detail-1{
                                width: 50%;
                                text-align: center;
                                h1{
                                    font-size: 30px;
                                    margin-bottom: 10px;
                                    
                                }
                                p{
                                    line-height: 1.5;
                                    color: gray;
                                    margin: 10px ;
                                }
                                .book{
                                    height: 5vh;
                                    width: 50%;
                                    margin-top: 10px;
                                    border: none;
                                    background-color:#2779a7;
                                    border-radius: 40px;
                                    font-size: 16px;
                                    color:#fff;
                                    cursor: pointer;
                                }
                                
                            }
                            
                        }
                    }
                    .card{
                        .third-card{
                            display:flex;
                            flex-direction: row;
                            width: 100%;
                            height: 31vh;
                            background-color: #fff;
                            justify-content: space-evenly;
                            align-items: center;
                            border-radius: 20px;
                            .c-one{
                                width: 190px ;
                                height: 190px ;
                                background: url(../../assets/image/Jeep.jpg);
                                border-radius: 15px;
                            }
                            .detail-1{
                                width: 50%;
                                text-align: center;
                                h1{
                                    font-size: 30px;
                                    margin-bottom: 10px;
                                    
                                }
                                p{
                                    line-height: 1.5;
                                    color: gray;
                                    margin: 10px ;
                                }
                                .book{
                                    height: 5vh;
                                    width: 50%;
                                    margin-top: 10px;
                                    border: none;
                                    background-color:#2779a7;
                                    border-radius: 40px;
                                    font-size: 16px;
                                    color:#fff;
                                    cursor: pointer;
                                }
                                
                            }
                            
                        }
                    }
                }
            }
        }
       }
      }
    }


  }
@media screen and (max-width:992px){

    .container{
        max-width: 960px;
    }


  }
    
  

  @media screen and (min-width:1200px){
    .container{
        max-width: 1140px;
        .title{
            .part2{
                .book{
                    left: 135px !important;
                }
            }
        }
        
    }
   
    }
    @media screen and (max-width:1300px){
        .container{
            max-width: 1140px;
            .title{
                .part2{
                    .book{
                        left: 135px !important;
                    }
                }
            }
            .card-second{
                .first-card{
                    justify-content: center !important;  
                    
                }
                .second-card{
                    justify-content: center !important; 
                    
                }
            }
        }
       
        }
    @media screen and (min-width:1300px){
        .container{
            max-width: 1140px;
            .title{
                .part2{
                    .book{
                        left: 150px ;
                    }
                }
            }
        }
        .card-second{
            .first-card{
              justify-content: space-evenly !important; 
            }
            .second-card{
               justify-content: space-evenly !important; 
          }
        }

    }
    
        @media screen and (min-width:1400px){
            .container{
                max-width: 1140px;
                .title{
                    .part2{
                        .book{
                            left: 160px !important;
                        }
                    }
                }
            }
           
            }