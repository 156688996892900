

.container-t{
    width: 100%;
    
    margin-right: auto;
    margin-left: auto;
    .content-slick {
        text-align: center;
        margin-bottom: 50px;
    }
    .container-slick{
        margin: 0 auto;
        width: 100% !important;
      
        
    
        .box-slick{
            display: flex !important;
            flex-direction: row;
            width: 95% !important;
            margin-left: 10px;
            height: 250px;
            border-radius: 20px;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;
            
            margin-bottom: 60px;
        
            .part-first{
                width: 49%;
                padding-left: 0px;
                .carousel-img{
                    width: 100%;
                    height: 215px;
                    border-radius: 15px;
                    margin-left: 20px;
                }
            }
            .part-second{
                width: 50%;
                height: 215px;
                display: flex;
                flex-direction: column;
                text-align: center ;
                align-items: center;
                justify-content: space-between;
                //gap: 20px;
                .head-1{
                    font-size: 35px;
                    font-weight: 600;
                    //margin-bottom: 5px;
                }
                .p-1{
                    width: 85%;
                    color: gray;
                }
                
                
                
                .book{
                    height: 40px;
                    width: 50%;
                    //margin-top: 5px;
                    border: none;
                    background-color:#144866;
                    border-radius: 40px;
                    font-size: 16px;
                    color:#fff;
                    cursor: pointer;
                    a{
                        text-decoration: none;
                        color: #fff;
                    }                              
                }
            }
        }
     
    
        
    }

    @media screen and (min-width:574px){ 
    .container-slick-1{
        margin: 0 auto;
        width: 100% !important;
        display: none;
      
        
    
        .box-slick{
            display: flex !important;
            flex-direction: row;
            width: 95% !important;
            margin-left: 10px;
            height: 250px;
            border-radius: 20px;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;
            
            margin-bottom: 60px;
        
            .part-first{
                width: 49%;
                padding-left: 0px;
                .carousel-img{
                    width: 100%;
                    height: 215px;
                    border-radius: 15px;
                    margin-left: 20px;
                }
            }
            .part-second{
                width: 50%;
                height: 215px;
                display: flex;
                flex-direction: column;
                text-align: center ;
                align-items: center;
                justify-content: space-between;
                //gap: 20px;
                .head-1{
                    font-size: 35px;
                    font-weight: 600;
                    //margin-bottom: 5px;
                }
                .p-1{
                    width: 85%;
                    color: gray;
                }
                
                
                
                .book{
                    height: 40px;
                    width: 50%;
                    //margin-top: 5px;
                    border: none;
                    background-color:#144866;
                    border-radius: 40px;
                    font-size: 16px;
                    color:#fff;
                    cursor: pointer;
                    a{
                        text-decoration: none;
                        color: #fff;
                    }                              
                }
            }
        }
     
    
        
    }
}
}





.slick-dots li button:before {
    font-size: medium;
}
.slick-next:before,
.slick-prev:before{
    background-color:rgb(239, 238, 238) ;
    font-size: 0px;
} 



@media screen and (max-width:390px){

    .container-t{
        width: 100% !important;
        
        margin-right: 0px;
        margin-left: 0px;
    }
    .content-slick {
        text-align: center;
        margin-bottom: 0px !important;
    }
    
    .container-slick{
        margin: 0px !important;
        width: 100% !important;
        padding-bottom: 30px;
        display: none;
        .slick-list{
            width: 90vw !important;
            height: 12vh !important ;
        }
        
        
    
        .box-slick{
            display: flex !important;
            flex-direction: row;
            width: 95% !important;
            margin-left: 2px !important;
            height: 12vh !important;
            border-radius: 6px !important;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;
            
            margin-bottom: 60px;
        
            .part-first{
                width: 50%;
                //padding-left: 5px !important;
                .carousel-img{
                    width: 70px !important;
                    height: 70px !important;
                    border-radius: 4px !important;
                    margin-left: 9px !important ;
                }
            }
            .part-second{
                width: 50%;
                height: 9vh !important;
                display: flex;
                flex-direction: column;
                text-align: center ;
                align-items: center;
                justify-content: space-between;
                //gap: 20px;
                .head-1{
                    font-size: 14px !important;
                    font-weight: 600;
                    //margin-bottom: 5px;
                }
                .p-1{
                    width: 85%;
                    color: gray;
                    font-size: 6px !important;
                }
                
                
                
                .book{
                    height: 15px !important;
                    width: 70% !important;
                    //margin-top: 3px;
                    border: none;
                    background-color:#144866;
                    border-radius: 40px;
                    font-size: 8px !important;
                    color:#fff;
                    padding-top: 2px;
                    cursor: pointer;
                    a{
                        text-decoration: none;
                        color: #fff;
                        //margin-bottom: -2px;
                    }                              
                }
            }
        }

        .slick-dots{
            width: 100% !important;
            
        }
      
        
    }
    .container-slick-1{
        margin: 0px !important;
        width: 100% !important;
        padding-bottom: 30px;
        
        .slick-list{
            width: 100% !important;
            height: 20vh !important ;
        }
        
        
    
        .box-slick-1{
            display: flex !important;
            flex-direction: row;
            width: 100% !important;
            margin-left: 2px !important;
            height: 20vh !important;
            border-radius: 8px !important;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;
            
            margin-bottom: 60px;
        
            .part-first-1{
                width: 50%;
                //padding-left: 5px !important;
                .carousel-img-1{
                    width: 98% !important;
                    height: 150px !important;
                    border-radius: 6px !important;
                    margin-left: 15px !important ;
                }
            }
            .part-second-1{
                width: 50%;
                height: 18vh !important;
                display: flex;
                flex-direction: column;
                text-align: center ;
                align-items: center;
                justify-content: space-between;
                padding: 10px;
                //gap: 20px;
                .head-1{
                    font-size: 25px !important;
                    font-weight: 600;
                    //margin-bottom: 5px;
                }
                .p-1{
                    width: 85%;
                    color: gray;
                    font-size: 12px !important;
                }
                
                
                
                .book{
                    height: 25px !important;
                    width: 70% !important;
                    //margin-top: 3px;
                    border: none;
                    background-color:#144866;
                    border-radius: 40px;
                    font-size: 12px !important;
                    color:#fff;
                    padding-top: 2px;
                    cursor: pointer;
                    a{
                        text-decoration: none;
                        color: #fff;
                        //margin-bottom: -2px;
                    }                              
                }
            }
        }

        .slick-dots{
            width: 100% !important;
            
        }
      
        
    }
    .slick-dots li{
        width: 10px !important;
        height: 10px !important;
    }
    
    .slick-dots li button:before {
        font-size: 10px;
    }
    .slick-next:before,
    .slick-prev:before{
        background-color:rgb(239, 238, 238) ;
        font-size: 0px;
    } 
}

@media screen and (max-width:450px){

    .container-t{
        width: 100% !important;
        
        margin-right: 0px;
        margin-left: 0px;
    }
    .content-slick {
        text-align: center;
        margin-bottom: 0px !important;
    }
    
    .container-slick{
        margin: 0px !important;
        width: 100% !important;
        padding-bottom: 30px;
        display: none;
        .slick-list{
            width: 90vw !important;
            height: 12vh !important ;
        }
        
        
    
        .box-slick{
            display: flex !important;
            flex-direction: row;
            width: 95% !important;
            margin-left: 2px !important;
            height: 12vh !important;
            border-radius: 6px !important;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;
            
            margin-bottom: 60px;
        
            .part-first{
                width: 50%;
                //padding-left: 5px !important;
                .carousel-img{
                    width: 70px !important;
                    height: 70px !important;
                    border-radius: 4px !important;
                    margin-left: 9px !important ;
                }
            }
            .part-second{
                width: 50%;
                height: 9vh !important;
                display: flex;
                flex-direction: column;
                text-align: center ;
                align-items: center;
                justify-content: space-between;
                //gap: 20px;
                .head-1{
                    font-size: 14px !important;
                    font-weight: 600;
                    //margin-bottom: 5px;
                }
                .p-1{
                    width: 85%;
                    color: gray;
                    font-size: 6px !important;
                }
                
                
                
                .book{
                    height: 15px !important;
                    width: 70% !important;
                    //margin-top: 3px;
                    border: none;
                    background-color:#144866;
                    border-radius: 40px;
                    font-size: 8px !important;
                    color:#fff;
                    padding-top: 2px;
                    cursor: pointer;
                    a{
                        text-decoration: none;
                        color: #fff;
                        //margin-bottom: -2px;
                    }                              
                }
            }
        }

        .slick-dots{
            width: 100% !important;
            
        }
      
        
    }
    .container-slick-1{
        margin: 0px !important;
        width: 100% !important;
        padding-bottom: 30px;
        
        .slick-list{
            width: 100% !important;
            height: 20vh !important ;
        }
        
        
    
        .box-slick-1{
            display: flex !important;
            flex-direction: row;
            width: 100% !important;
            margin-left: 2px !important;
            height: 20vh !important;
            border-radius: 8px !important;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;
            
            margin-bottom: 60px;
        
            .part-first-1{
                width: 50%;
                //padding-left: 5px !important;
                .carousel-img-1{
                    width: 98% !important;
                    height: 150px !important;
                    border-radius: 6px !important;
                    margin-left: 15px !important ;
                }
            }
            .part-second-1{
                width: 50%;
                height: 18vh !important;
                display: flex;
                flex-direction: column;
                text-align: center ;
                align-items: center;
                justify-content: space-between;
                padding: 10px;
                //gap: 20px;
                .head-1{
                    font-size: 25px !important;
                    font-weight: 600;
                    //margin-bottom: 5px;
                }
                .p-1{
                    width: 85%;
                    color: gray;
                    font-size: 12px !important;
                }
                
                
                
                .book{
                    height: 25px !important;
                    width: 70% !important;
                    //margin-top: 3px;
                    border: none;
                    background-color:#144866;
                    border-radius: 40px;
                    font-size: 12px !important;
                    color:#fff;
                    padding-top: 2px;
                    cursor: pointer;
                    a{
                        text-decoration: none;
                        color: #fff;
                        //margin-bottom: -2px;
                    }                              
                }
            }
        }

        .slick-dots{
            width: 100% !important;
            
        }
      
        
    }
    .slick-dots li{
        width: 10px !important;
        height: 10px !important;
    }
    
    .slick-dots li button:before {
        font-size: 10px;
    }
    .slick-next:before,
    .slick-prev:before{
        background-color:rgb(239, 238, 238) ;
        font-size: 0px;
    } 
}

@media screen and (min-width: 700px) and (max-width: 950px){

    .container-t{
        width: 100% !important;
        
        margin-right: 0px;
        margin-left: 0px;
    }
    .content-slick {
        text-align: center;
        margin-bottom: 0px !important;
    }
    
    .container-slick{
        margin: 0px !important;
        width: 100% !important;
        padding-bottom: 30px;
        .slick-list{
            width: 97vw;
            height: 240px ;
        }
        
        
    
        .box-slick{
            display: flex !important;
            flex-direction: row;
            width: 95% !important;
            margin-left: 2px !important;
            height: 200px !important ;
            border-radius: 6px !important;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;
            
            margin-bottom: 10px;
        
            .part-first{
                width: 50%;
                padding-left: 5px !important;
                .carousel-img{
                    width: 120px ;
                    height: 170px !important ;
                    border-radius: 6px !important ;
                    margin-left: 9px !important  ;
                }
            }
            .part-second{
                width: 50%;
                height: 170px !important;
                display: flex;
                flex-direction: column;
                text-align: center ;
                align-items: center;
                justify-content: space-between;
                //gap: 20px;
                .head-1{
                    font-size: 28px !important ;
                    font-weight: 600;
                    //margin-bottom: 5px;
                }
                .p-1{
                    width: 85%;
                    color: gray;
                    font-size: 10px ;
                }
                
                
                
                .book{
                    height: 18px;
                    width: 60% !important;
                    //margin-top: 3px;
                    border: none;
                    background-color:#144866;
                    border-radius: 40px;
                    font-size: 14px !important;
                    color:#fff;
                    padding-top: 0px !important;
                    cursor: pointer;
                    a{
                        text-decoration: none;
                        color: #fff;
                        //margin-bottom: -2px;
                    }                              
                }
            }
        }

        .slick-dots{
            width: 100% !important;
            
        }
      
        
    }
    .slick-dots li{
        width: 10px !important;
        height: 10px !important;
    }
    
    .slick-dots li button:before {
        font-size: 10px;
    }
    .slick-next:before,
    .slick-prev:before{
        background-color:rgb(239, 238, 238) ;
        font-size: 0px;
    } 
}
