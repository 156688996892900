*{
    box-sizing: border-box;
}

.booking{
    padding-top: 80px;
    padding-bottom: 80px;
    
    .container-c{
        background-color: rgb(239, 238, 238);
        padding: 90px 125px;
    .container{
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        .contact{
            display: flex;
            flex-direction: row;
            
            .partone{
                width: 50%;
                display: flex;
                flex-direction: column;
                .top-section{
                    h1{
                        margin: 0%;
                        width: 80%;
                        font-size: 45px;
                        line-height: 1.2;
                        font-weight: 500;
                    }
                    p{
                        width: 80%;
                        line-height: 1.5;
                        font-size: 19px;
                        text-align: justify;
                    }
                }
                .bottom-section{
                    
                    .one{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        .symbol{
                            width: 10%;
                            margin-top: 15px;
                            img{
                                width: 40px;
                                height: 35px;
                              }
                        }
                        .detail-one{
                            width: 90%;
                            h2{
                                font-size: 20px;
                            }
                            p{
                                text-align: justify;
                                line-height: 1.5;
                                font-size: 16px;
                            }
                        }
                    }
                    .two{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        .symbol{
                            width: 10%;
                            margin-top: 15px;
                            img{
                                width: 35px;
                                height: 35px;
                              }
                        }
                        .detail-one{
                            width: 90%;
                            h2{
                                font-size: 20px;
                            }
                            p{
                                text-align: justify;
                                line-height: 1.5;
                                font-size: 16px;
                            }
                        }
                    }
                    .three{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        .symbol{
                            width: 10%;
                            margin-top: 15px;
                            img{
                                width: 40px;
                                height: 35px;
                              }
                        }
                        .detail-one{
                            width: 90%;
                            h2{
                                font-size: 20px;
                            }
                            p{
                                text-align: justify;
                                line-height: 1.5;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
            .parttwo{
                width: 50%;
                background-color: rgb(239, 238, 238);
                border-radius: 30px;
                
                
                .pic{
                    background: url(../../assets/image/cardpic.jpg)no-repeat;
                    border-radius: 20px;
                    width: 555px;
                    height: 820px;
                    //margin-top: 35px;
                    //margin-left: 35px; 
                    background-position: center;
                    background-size: cover; 
                }
            }

        }
    }
}
}

@media screen and (max-width:390px){

    .container{
        width: 100%;
        .contact{
            display: flex;
            flex-direction: column-reverse !important;
            
            .partone{
                width: 100% !important;
                display: flex;
                flex-direction: row !important;
                margin-top: 10px;
                .top-section{
                    width: 50% !important;
                    h1{
                        margin: 0%;
                        width: 98% !important;
                        font-size: 20px !important;
                        line-height: 1.2;
                        font-weight: 600 !important;
                    }
                    p{
                        width: 98% !important;
                        line-height: 1.3 !important;
                        font-size: 8px !important;
                        text-align: justify;
                    }
                }
                
                
                .bottom-section{
                    
                    width: 50% !important;
                    .one{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        .symbol{
                            width: 10%;
                        }
                        .detail-one{
                            width: 90%;
                            h2{
                                font-size: 12px !important;
                            }
                            p{
                                text-align: justify;
                                line-height: 1.3 !important;
                                font-size: 8px !important;
                            }
                        }
                    }
                    .two{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        .symbol{
                            width: 10%;
                        }
                        .detail-one{
                            width: 90%;
                            h2{
                                font-size: 12px !important;
                            }
                            p{
                                text-align: justify;
                                line-height: 1.5;
                                font-size: 8px !important;
                            }
                        }
                    }
                    .three{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        .symbol{
                            width: 10%;
                        }
                        .detail-one{
                            width: 90%;
                            h2{
                                font-size: 12px !important;
                            }
                            p{
                                text-align: justify;
                                line-height: 1.5;
                                font-size: 8px !important;
                            }
                        }
                    }
                }
            }
            .parttwo{
                width: 100% !important;
                background-color: rgb(239, 238, 238);
                border-radius: 30px;
                
                
                .pic{
                    background: url(../../assets/image/cardpic.jpg)no-repeat;
                    border-radius: 12px !important;
                    width:100% !important;
                    height: 200px !important;
                    //margin-top: 35px;
                    //margin-left: 35px; 
                    background-position: center;
                    background-size: cover; 
                }
            }

        }
    }


  }

@media screen and (max-width:450px){

    .container{
        width: 100%;
        .contact{
            display: flex;
            flex-direction: column-reverse !important;
            
            .partone{
                width: 100% !important;
                display: flex;
                flex-direction: column !important;
                margin-top: 10px;
                .top-section{
                    width: 100% !important;
                    h1{
                        text-align: center;
                        margin: 0%;
                        width: 98% !important;
                        font-size: 20px !important;
                        line-height: 1.2;
                        font-weight: 600 !important;
                    }
                    p{
                        width: 98% !important;
                        line-height: 1.3 !important;
                        font-size: 14px !important;
                        text-align: justify;
                    }
                }
                
                
                .bottom-section{
                   //display:none ; 
                width: 100% !important;
                    .one{
                        display: flex;
                        flex-direction: column !important;
                        justify-content: space-between;
                        align-items: center;
                        .symbol{
                            width: 10%;
                            margin-left: 5px;
                            margin-right: 10px;
                            margin-top: 5px !important;
                            img{
                                width: 30px !important;
                                height: 30px !important;
                              }
                        }
                        .detail-one{
                            width: 100% !important;
                            text-align: center;
                            h2{
                                font-size: 14px !important;
                            }
                            p{
                                text-align: center !important;
                                line-height: 1.5;
                                width: 100%;
                                font-size: 12px !important;
                            }
                        }
                    }
                    .two{
                        display: flex;
                        flex-direction: column !important;
                        justify-content: space-between;
                        align-items: center;
                        .symbol{
                            width: 10%;
                            margin-left: 5px;
                            margin-right: 10px;
                            margin-top: 5px !important;
                            img{
                                width: 30px !important;
                                height: 30px !important;
                              }
                        }
                        .detail-one{
                            width: 100% !important;
                            text-align: center;
                            h2{
                                font-size: 14px !important;
                            }
                            p{
                                text-align: center !important;
                                line-height: 1.5;
                                width: 100%;
                                font-size: 12px !important;
                            }
                        }
                    }
                    .three{
                        display: flex;
                        flex-direction: column !important;
                        justify-content: space-between;
                        align-items: center;
                        .symbol{
                            width: 10%;
                            margin-left: 5px;
                            margin-right: 10px;
                            margin-top: 5px !important;
                            img{
                                width: 30px !important;
                                height: 30px !important;
                              }
                        }
                        .detail-one{
                            width: 100% !important;
                            text-align: center;
                            h2{
                                font-size: 14px !important;
                            }
                            p{
                                text-align: center !important;
                                line-height: 1.5;
                                width: 100%;
                                font-size: 12px !important;
                            }
                        }
                    }
                }
            }
            .parttwo{
                width: 100% !important;
                background-color: rgb(239, 238, 238);
                border-radius: 30px;
                
                
                .pic{
                    background: url(../../assets/image/cardpic.jpg)no-repeat;
                    border-radius: 12px !important;
                    width:100% !important;
                    height: 200px !important;
                    //margin-top: 35px;
                    //margin-left: 35px; 
                    background-position: center;
                    background-size: cover; 
                }
            }

        }
    }


  }



  @media screen and (min-width: 700px) and (max-width: 950px){

    .container{
        width: 100%;
        .contact{
            display: flex;
            flex-direction: column-reverse !important;
            
            .partone{
                width: 100% !important;
                display: flex;
                flex-direction: row !important;
                margin-top: 25px;
                .top-section{
                    width: 51% ;
                    h1{
                        margin: 0%;
                        width: 98% !important;
                        font-size: 24px !important;
                        line-height: 1.2;
                        font-weight: 600 !important;
                    }
                    p{
                        width: 98% !important;
                        line-height: 1.5;
                        font-size: 14px !important;
                        text-align: justify;
                    }
                }
                
                
                .bottom-section{
                    
                    width: 50% !important;
                    .one{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        .symbol{
                            width: 10%;
                            margin-left: 5px;
                            margin-right: 15px;
                            img{
                                width: 25px;
                                height: 25px;
                              }
                        }
                        .detail-one{
                            width: 90%;
                            h2{
                                font-size: 16px !important;
                            }
                            p{
                                text-align: justify;
                                line-height: 1.3 !important;
                                font-size: 14px !important;
                            }
                        }
                    }
                    .two{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        .symbol{
                            width: 10%;
                            margin-left: 5px;
                            margin-right: 15px;
                            img{
                                width: 25px;
                                height: 25px;
                              }
                        }
                        .detail-one{
                            width: 90%;
                            h2{
                                font-size: 16px !important;
                            }
                            p{
                                text-align: justify;
                                line-height: 1.3 !important;
                                font-size: 14px !important;
                            }
                        }
                    }
                    .three{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        .symbol{
                            width: 10%;
                            margin-left: 5px;
                            margin-right: 15px;
                            img{
                                width: 25px;
                                height: 25px;
                              }
                        }
                        .detail-one{
                            width: 90%;
                            h2{
                                font-size: 16px !important;
                                word-break: break-all;
                                width: 55%;
                            }
                            p{
                                text-align: justify;
                                line-height: 1.3 !important;
                                font-size: 14px !important;
                            }
                        }
                    }
                }
            }
            .parttwo{
                width: 100% !important;
                background-color: rgb(239, 238, 238);
                border-radius: 30px;
                
                
                .pic{
                    background: url(../../assets/image/cardpic.jpg)no-repeat;
                    border-radius: 12px !important;
                    width:100% !important;
                    height: 350px !important;
                    //margin-top: 35px;
                    //margin-left: 35px; 
                    background-position: center;
                    background-size: cover; 
                }
            }

        }
    }


  }
@media screen and (max-width:992px){

    .container{
        max-width: 960px;
    }


  }
    
  

  @media screen and (min-width:1200px){
    .container{
        max-width: 1140px;
    }
   
    }

@media screen and (max-width:1300px ) {
    .container{
        max-width: 1140px;
        .contact{
           
            
            .partone{
                
                h1{
                   
                    font-size: 40px !important ;
                    line-height: 1.2 !important;
                    font-weight: 500;
                }
                p{
                    width: 85%;
                    line-height: 1.5;
                    font-size: 18px;
                }
                .bottom-section{
                    .one{
                        
                        symbol{
                            width: 10%;
                        }
                        .detail-one{
                            width: 90%;
                            h2{
                                font-size: 20px;
                                margin: 0%;
                            }
                            p{
                                line-height: 1.5;
                                font-size: 16px;
                            }
                        }
                    }
                    .two{
                        
                        symbol{
                            width: 10%;
                        }
                        .detail-one{
                            width: 90%;
                            h2{
                                font-size: 20px;
                                margin: 0;
                            }
                            p{
                                line-height: 1.5;
                                font-size: 16px;
                            }
                        }
                    }
                    .three{
                        
                        symbol{
                            width: 10%;
                        }
                        .detail-one{
                            width: 90%;
                            h2{
                                font-size: 20px;
                                margin: 0%;
                            }
                            p{
                                line-height: 1.5;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
            .parttwo{
                width: 55% ;
                background-color: rgb(239, 238, 238);
                border-radius: 30px;
                
                
                .pic{
                    background: url(../../assets/image/cardpic.jpg)no-repeat;
                    border-radius: 20px;
                    width: 555px;
                    height: 820px;
                    //margin-top: 25px !important;
                    //margin-left: 25px !important;  
                }
            }

        }
    
    }
}
@media screen and (min-width:1400px){
    .container{
        width: 1140px;
        .contact{
           
            
            .partone{
                
                h1{
                    
                    font-size: 45px;
                    
                }
                p{
                    width: 80%;
                    line-height: 1.5;
                    font-size: 19px;
                }
                .bottom-section{
                    .one{
                        
                        symbol{
                           
                        }
                        .detail-one{
                            
                            h2{
                                font-size: 20px;
                            }
                            p{
                                line-height: 1.5;
                                font-size: 16px;
                            }
                        }
                    }
                    .two{
                        
                        symbol{
                           
                        }
                        .detail-one{
                           
                            h2{
                                font-size: 20px;
                            }
                            p{
                                line-height: 1.5;
                                font-size: 16px;
                            }
                        }
                    }
                    .three{
                        
                        symbol{
                            
                        }
                        .detail-one{
                            
                            h2{
                                font-size: 20px;
                            }
                            p{
                                line-height: 1.5;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
            .parttwo{
                
                
                
                .pic{
                   
                   // margin-top: 35px ;
                    //margin-left: 35px;  
                }
            }

        }
    }
    
}
