* {
  box-sizing: border-box;
}

.fixed-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: transparent;
  width: 100%;
  padding: 15px 125px;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.flex-group-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  .content-one {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    height: 90px;
    width: 15%;
    .logo-out {
      width: 155px;
      a {
        text-decoration: none;
        color: #474747;
        cursor: pointer;
        position: relative;

        .logo-head {
          width: 150px;
          height: 150px;
          position: absolute;
          top: -115px;
          left: -25px;
        }
      }
    }
    a {
      text-decoration: none;
      color: #474747;
      cursor: pointer;
      position: relative;
    }

    h1 {
      font-size: 50px;
      margin: 0;
      cursor: pointer;
      color: #474747;
      font-weight: 500;
    }
  }
  .content-two {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    //width: 90%;
    gap: 10px;
    margin-bottom: 10px;
    img {
      margin-left: 0px;
      margin-right: 5px;
      margin-bottom: -5px;
    }
    h3 {
      color: #060606;
      font-weight: 500;
      margin: 0px;
      width: 170px;
      position: relative;
      bottom: 5px;
    }

    .w-app {
          position: fixed;
    right: 2%;
    bottom: 2%;
    z-index: 1050;
      .whatsapp {
        width: 100px;
        height: 100px;
      }
    }
    a {
      text-decoration: none;
      color: #060606;
      cursor: pointer;
      font-size: 1.3rem;
      font-weight: 400;
      margin: 0 20px;
    }
    .a-phone{
      text-decoration: underline;
      color: #060606;
      cursor: pointer;
      font-size: 1.3rem;
      font-weight: 400;
      margin: 0 20px; 
    }
  }
  button {
    border: none;
    background-color: #fff;
    position: absolute;
    //width: 30px;
    display: none;
  }
}

@media screen and (max-width: 390px) {
  .fixed-header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1020;
    background-color: transparent;
    width: 100% !important;
    padding: 15px !important ;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .container {
    width: 100% !important;

    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    .flex-group-1 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      .content-one {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
        height: 90px;
        width: 15%;
        .logo-out {
          width: 155px;
          a {
            text-decoration: none;
            color: #474747;
            cursor: pointer;
            position: relative;

            .logo-head {
              width: 150px;
              height: 150px;
              position: absolute;
              top: -115px;
              left: -5px !important;
            }
          }
        }
        a {
          text-decoration: none;
          color: #474747;
          cursor: pointer;
          position: relative;
        }

        h1 {
          font-size: 50px;
          margin: 0;
          cursor: pointer;
          color: #474747;
          font-weight: 500;
        }
      }
      .content-two {
        display: none;
      }
      button {
        border: none;
        background-color: transparent;
        position: absolute;
        //width: 30px;
        display: block;
        right: 20px;
      }

      .list-modal {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1030;
        background: rgba(0, 0, 0, 0.9);
        overflow: hidden;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
        margin-top: 0;
        .content-list {
          width: 80%;
          position: absolute;
          right: 0px;
          top: 0px;
          padding: 10px;
          border-radius: 4px;
          background-color: #fff;
          box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
            rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
          display: flex;
          flex-direction: column;
          .phone {
            display: flex;
            align-items: left;
          }
          a {
            text-decoration: none;
            color: #060606;

            cursor: pointer;
            position: relative;

            margin-bottom: 7px;
          }
          .w-app {
            position: sticky;
            bottom: 10px;
            right: 10px;
            .whatsapp {
              width: 25px;
              height: 25px;
            }
          }
          img {
            margin-right: 5px;
            width: 17px;
            height: 17px;
          }
          h3 {
            font-size: 16px;
            margin: 0px !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .fixed-header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1020;
    background-color: transparent;
    width: 100% !important;
    padding: 15px !important ;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .container {
    width: 100% !important;

    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    .flex-group-1 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      .content-one {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
        height: 90px;
        width: 15%;
        .logo-out {
          width: 155px;
          a {
            text-decoration: none;
            color: #474747;
            cursor: pointer;
            position: relative;

            .logo-head {
              width: 150px;
              height: 150px;
              position: absolute;
              top: -115px;
              left: -5px !important;
            }
          }
        }
        a {
          text-decoration: none;
          color: #474747;
          cursor: pointer;
          position: relative;
        }

        h1 {
          font-size: 50px;
          margin: 0;
          cursor: pointer;
          color: #474747;
          font-weight: 500;
        }
      }
      .content-two {
        display: none;
      }
      button {
        border: none;
        background-color: transparent;
        position: absolute;
        //width: 30px;
        display: block;
        right: 20px;
        img{
          width: 30px;
          height: 30px;
        }
      }
      .list-modal {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1030;
        background: rgba(0, 0, 0, 0.9);
        overflow: hidden;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
        margin-top: 0;
        .content-list {
          width: 80%;
          height: 100vh;
          position: absolute;
          right: 0px;
          top: 0px;
          padding: 10px;
          padding-left: 20px;
          border-radius: 4px;
          background-color: #fff;
          box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
            rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
          display: flex;
          flex-direction: column;
          .close-btn{
            position: absolute;
            top: 25px !important;
            right: 25px !important;
         
          }
          .phone {
            display: flex;
            align-items: left;
            padding-top: 20px;
            padding-bottom: 10px;
            border-bottom: 2px solid #f6f7fb;
            margin-bottom: 10px;
            .phone-btn{
              font-size: 1.6rem;
              font-weight: 600;
              img{
                margin-right: 5px;
                margin-bottom: -2px;
              }
            }
          }
          .mav-link{
            display: block;
            padding: 0.5rem 1rem;
            line-height: 1.7;
            font-size: 25px;
            font-weight: 500;
            color: #060606;
            transition: all 0.2s ease-in-out;
        }
          
          a {
            text-decoration: none;
            color: #060606;
            cursor: pointer;
            position: relative;

            margin-bottom: 7px;
          }

          .w-app {
            .whatsapp {
              position: fixed;
              bottom: 10px;
              width: 5rem;
              right: 10px;
              height: 5rem;
            }
          }
          // img {
          //   margin-right: 5px;
          //   width: 17px;
          //   height: 17px;
          // }
          h3 {
            font-size: 16px;
            margin-top: 0px;
            margin-bottom: 20px !important;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 700px) and (max-width: 950px) {
  .fixed-header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1020;
    background-color: transparent;
    width: 100% !important;
    padding: 15px !important ;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .container {
    width: 100% !important;

    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    .flex-group-1 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      .content-one {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
        height: 90px;
        width: 15%;
        .logo-out {
          width: 155px;
          a {
            text-decoration: none;
            color: #474747;
            cursor: pointer;
            position: relative;

            .logo-head {
              width: 150px;
              height: 150px;
              position: absolute;
              top: -115px;
              left: -5px !important;
            }
          }
        }
        a {
          text-decoration: none;
          color: #474747;
          cursor: pointer;
          position: relative;
        }

        h1 {
          font-size: 50px;
          margin: 0;
          cursor: pointer;
          color: #474747;
          font-weight: 500;
        }
      }
      .content-two {
        display: none;
      }
      button {
        border: none;
        background-color: transparent;
        position: absolute;
        //width: 30px;
        display: block;
        right: 20px;
        img{
          width: 40px;
          height: 40px;
        }
      }
      .list-modal {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1030;
        background: rgba(0, 0, 0, 0.9);
        overflow: hidden;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
        margin-top: 0;

        .content-list {
          width: 80%;
          position: absolute;
          right: 0px;
          top: 0px;
          padding: 20px;
          height: 100vh;
          border-radius: 4px;
          background-color: #fff;
          box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
            rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
          display: flex;
          flex-direction: column;
          overflow-y: auto;
          animation-name: modalFade;
          animation-duration: 0.8s;

          @keyframes modalFade {
            from {
              transform: translateX(100%);
              opacity: 0;
            }

            to {
              transform: translateX(0);
              opacity: 1;
            }
          }
          .phone {
            display: flex;
            align-items: left;
            flex-direction: column;
          }
          .w-app {
            position: relative;
            right: 10px;
            bottom: -10px;
            .whatsapp {
              width: 70px;
              height: 70px;
            }
          }
          a {
            text-decoration: none;
            color: #060606;
            cursor: pointer;
            position: relative;
            font-size: 24px;
            margin-bottom: 20px;
          }
          img {
            margin-right: 5px;
            width: 17px;
            height: 17px;
          }
          h3 {
            font-size: 24px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
