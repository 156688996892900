*{
    box-sizing: border-box;
}

.booking{
    padding-top: 80px;
    
    
.container-l{
 background-color: #fff;
 padding: 50px 125px;
 //padding-bottom: 120px;


 
 
.container{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    .title{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 40px;
        padding-top: 100px;
        .part1{
            width: 70%;
            h1{
                font-size: 45px;
                font-weight: 600;
                margin: 0%;
            }
            p{
                color: gray;
            }
            
        }
      
    }
    .card-second-2{
        .m-card{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
            padding-bottom: 25px;
            .item1{
                width: 32%;
                border-radius: 24px;
                padding-bottom: 20px;
                // background: rgb(254,219,142);
                // background: linear-gradient(180deg, rgba(254,219,142,1) 31%, rgba(255,255,255,1) 94%);
                .pic-1{
                    background: url(../../assets/image/dome_2.png);
                    background-position: center;
                    background-size: cover;
                    width: 100% !important;
                    height: 250px;
                    border-radius: 25px;
                }
                .h-detail{
                    padding: 0px 20px;
                    h3{
                        color: rgb(93, 91, 91);
                        font-weight: 500;
                        margin-bottom: 10px;
                    }
                    p{
                        font-size: 14px;
                        color: gray;
                        margin: 0;

                    }
                }
                .fare{
                    display: flex;
                    align-items: center;
                    padding: 5px 20px;
                    width: 100%;
                    h2{
                        font-weight: 400;
                        color: rgb(93, 91, 91);
                    }
                    p{
                        color: gray;
                        font-size: 14px;
                        padding-left: 10px;
                    }
                }
            }
            .item2{
                width: 32%;
                border-radius: 24px;
                // background: rgb(254,219,142);
                // background: linear-gradient(180deg, rgba(254,219,142,1) 31%, rgba(255,255,255,1) 94%);
                .pic-1{
                   background: url(../../assets/image/a-k-veedu.jpg);
                   background-position: center;
                   background-size: cover;
                   width: 100% !important; 
                   height: 250px;
                   border-radius: 25px;
                }
                .h-detail{
                    padding: 0px 20px;
                    h3{
                        color: rgb(93, 91, 91);
                        font-weight: 500;
                        margin-bottom: 10px;
                    }
                    p{
                        font-size: 14px;
                        color: gray;
                        margin: 0;
                    }
                }
                .fare{
                    display: flex;
                    align-items: center;
                    padding: 5px 20px;
                    width: 100%;
                    h2{
                        font-weight: 400;
                        color: rgb(93, 91, 91);
                    }
                    p{
                        color: gray;
                        font-size: 14px;
                        padding-left: 10px;
                    }
                }
            }
            .item3{
                width: 32%;
                border-radius: 24px;
                // background: rgb(254,219,142);
                // background: linear-gradient(180deg, rgba(254,219,142,1) 31%, rgba(255,255,255,1) 94%);
                .pic-1{
                   background: url(../../assets/image/drone_night.png);
                   background-position: center;
                   background-size: cover;
                   width: 100% !important; 
                   height: 250px;
                   border-radius: 25px; 
                }
                .h-detail{
                    padding: 0px 20px;
                    h3{
                        color: rgb(93, 91, 91);
                        font-weight: 500;
                        margin-bottom: 10px;
                    }
                    p{
                        font-size: 14px;
                        color: gray;
                        margin: 0;
                    }
                }
                .fare{
                    display: flex;
                    align-items: center;
                    padding: 5px 20px;
                    width: 100%;
                    h2{
                        font-weight: 400;
                        color: rgb(93, 91, 91);
                    }
                    p{
                        color: gray;
                        font-size: 14px;
                        padding-left: 10px;
                    }
                }
            
            }
            .item4{
                width: 32%;
                padding-bottom: 20px;
                // border-radius: 24px;
                // background: rgb(254,219,142);
                // background: linear-gradient(180deg, rgba(254,219,142,1) 31%, rgba(255,255,255,1) 94%);
                .pic-1{
                    background: url(../../assets/image/purple-1.JPG);
                    background-position: center;
                    background-size: cover;
                    width: 100% !important;
                    height: 250px;
                    border-radius: 25px;
                }
                .h-detail{
                    padding: 0px 20px;
                    h3{
                        color: rgb(93, 91, 91);
                        font-weight: 500;
                        margin-bottom: 10px;
                    }
                    p{
                        font-size: 14px;
                        color: gray;
                        margin: 0;
                    }
                }
                .fare{
                    display: flex;
                    align-items: center;
                    padding: 5px 20px;
                    width: 100%;
                    h2{
                        font-weight: 400;
                        color: rgb(93, 91, 91);
                    }
                    p{
                        color: gray;
                        font-size: 14px;
                        padding-left: 10px;
                    }
                }
            }
            .item5{
                width: 32%;
                padding-bottom: 20px;
                // background: linear-gradient(180deg, rgba(254,219,142,1) 31%, rgba(255,255,255,1) 94%);
                .pic-1{
                    background: url(../../assets/image/a-frame.jpg);
                    background-position: top;
                    background-size: cover;
                    width: 100% !important;
                    height: 250px;
                    border-radius: 25px;
                }
                .h-detail{
                    padding: 0px 20px;
                    h3{
                        color: rgb(93, 91, 91);
                        font-weight: 500;
                        margin-bottom: 10px;
                    }
                    p{
                        font-size: 14px;
                        color: gray;
                        margin: 0;
                    }
                }
                .fare{
                    display: flex;
                    align-items: center;
                    padding: 5px 20px;
                    width: 100%;
                    h2{
                        font-weight: 400;
                        color: rgb(93, 91, 91);
                    }
                    p{
                        color: gray;
                        font-size: 14px;
                        padding-left: 10px;
                    }
                }
            }
            .item6{
                width: 32%;
                padding-bottom: 20px;
                // border-radius: 24px;
                // background: rgb(254,219,142);
                // background: linear-gradient(180deg, rgba(254,219,142,1) 31%, rgba(255,255,255,1) 94%);
                .pic-1{
                    background: url(../../assets/image/cabin.jpg);
                    background-position: center;
                    background-size: cover;
                    width: 100% !important;
                    height: 250px;
                    border-radius: 25px;
                }
                .h-detail{
                    padding: 0px 20px;
                    h3{
                        color: rgb(93, 91, 91);
                        font-weight: 500;
                        margin-bottom: 10px;
                    }
                    p{
                        font-size: 14px;
                        color: gray;
                        margin: 0;
                    }
                }
                .fare{
                    display: flex;
                    align-items: center;
                    padding: 5px 20px;
                    width: 100%;
                    h2{
                        font-weight: 400;
                        color: rgb(93, 91, 91);
                    }
                    p{
                        color: gray;
                        font-size: 14px;
                        padding-left: 10px;
                    }
                }
            }
            .item7{
                width: 32%;
                // border-radius: 24px;
                // background: rgb(254,219,142);
                // background: linear-gradient(180deg, rgba(254,219,142,1) 31%, rgba(255,255,255,1) 94%);
                .pic-1{
                    background: url(../../assets/image/tomorrowland.png);
                    background-position: center;
                    background-size: cover;
                    width: 100% !important;
                    height: 250px;
                    border-radius: 25px;
                }
                .h-detail{
                    padding: 0px 20px;
                    h3{
                        color: rgb(93, 91, 91);
                        font-weight: 500;
                        margin-bottom: 10px;
                    }
                    p{
                        font-size: 14px;
                        color: gray;
                        margin: 0;
                    }
                }
                .fare{
                    display: flex;
                    align-items: center;
                    padding: 5px 20px;
                    width: 100%;
                    h2{
                        font-weight: 400;
                        color: rgb(93, 91, 91);
                    }
                    p{
                        color: gray;
                        font-size: 14px;
                        padding-left: 10px;
                    }
                }
            }
        }
        .m-card-2{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            padding-bottom: 25px;
            .item1{
                width: 32%;
                border-radius: 24px;
                background: rgb(254,219,142);
                background: linear-gradient(180deg, rgba(254,219,142,1) 31%, rgba(255,255,255,1) 94%);
                .pic-1{
                    background: url(../../assets/image/purple-1.JPG);
                    background-position: center;
                    background-size: cover;
                    width: 355px;
                    height: 250px;
                    border-radius: 25px;
                }
                .h-detail{
                    padding: 0px 20px;
                    h3{
                        color: rgb(93, 91, 91);
                        font-weight: 500;
                        margin-bottom: 10px;
                    }
                    p{
                        font-size: 14px;
                        color: gray;
                        margin: 0;
                    }
                }
                .fare{
                    display: flex;
                    align-items: center;
                    padding: 5px 20px;
                    width: 100%;
                    h2{
                        font-weight: 400;
                        color: rgb(93, 91, 91);
                    }
                    p{
                        color: gray;
                        font-size: 14px;
                        padding-left: 10px;
                    }
                }
            }
            .item2{
                width: 32%;
                border-radius: 24px;
                background: rgb(254,219,142);
                background: linear-gradient(180deg, rgba(254,219,142,1) 31%, rgba(255,255,255,1) 94%);
                .pic-1{
                   background: url(../../assets/image/a-veedu.jpg);
                   background-position: center;
                   background-size: cover;
                   width: 355px; 
                   height: 250px;
                   border-radius: 25px;
                }
                .h-detail{
                    padding: 0px 20px;
                    h3{
                        color: rgb(93, 91, 91);
                        font-weight: 500;
                        margin-bottom: 10px;
                    }
                    p{
                        font-size: 14px;
                        color: gray;
                        margin: 0;
                    }
                }
                .fare{
                    display: flex;
                    align-items: center;
                    padding: 5px 20px;
                    width: 100%;
                    h2{
                        font-weight: 400;
                        color: rgb(93, 91, 91);
                    }
                    p{
                        color: gray;
                        font-size: 14px;
                        padding-left: 10px;
                    }
                }
            }
            .item3{
                width: 32%;
                border-radius: 24px;
                background: rgb(254,219,142);
                background: linear-gradient(180deg, rgba(254,219,142,1) 31%, rgba(255,255,255,1) 94%);
                .pic-1{
                   background: url(../../assets/image/drone.png);
                   background-position: center;
                   background-size: cover;
                   width: 355px; 
                   height: 250px;
                   border-radius: 25px; 
                }
                .h-detail{
                    padding: 0px 20px;
                    h3{
                        color: rgb(93, 91, 91);
                        font-weight: 500;
                        margin-bottom: 10px;
                    }
                    p{
                        font-size: 14px;
                        color: gray;
                        margin: 0;
                    }
                }
                .fare{
                    display: flex;
                    align-items: center;
                    padding: 5px 20px;
                    width: 100%;
                    h2{
                        font-weight: 400;
                        color: rgb(93, 91, 91);
                    }
                    p{
                        color: gray;
                        font-size: 14px;
                        padding-left: 10px;
                    }
                }
            
            }
            .item4{
                width: 32%;
                // border-radius: 24px;
                // background: rgb(254,219,142);
                // background: linear-gradient(180deg, rgba(254,219,142,1) 31%, rgba(255,255,255,1) 94%);
                .pic-1{
                    //background: url(../../assets/image/dome.jpg);
                    background-position: center;
                    background-size: cover;
                    width: 355px;
                    height: 250px;
                    border-radius: 25px;
                }
                .h-detail{
                    padding: 0px 20px;
                    h3{
                        color: rgb(93, 91, 91);
                        font-weight: 500;
                        margin-bottom: 10px;
                    }
                    p{
                        font-size: 14px;
                        color: gray;
                        margin: 0;
                    }
                }
                .fare{
                    display: flex;
                    align-items: center;
                    padding: 5px 20px;
                    width: 100%;
                    h2{
                        font-weight: 400;
                        color: rgb(93, 91, 91);
                    }
                    p{
                        color: gray;
                        font-size: 14px;
                        padding-left: 10px;
                    }
                }
            }
            .item5{
                width: 32%;
                // background: linear-gradient(180deg, rgba(254,219,142,1) 31%, rgba(255,255,255,1) 94%);
                .pic-1{
                   // background: url(../../assets/image/dome.jpg);
                    background-position: center;
                    background-size: cover;
                    width: 355px;
                    height: 250px;
                    border-radius: 25px;
                }
                .h-detail{
                    padding: 0px 20px;
                    h3{
                        color: rgb(93, 91, 91);
                        font-weight: 500;
                        margin-bottom: 10px;
                    }
                    p{
                        font-size: 14px;
                        color: gray;
                        margin: 0;
                    }
                }
                .fare{
                    display: flex;
                    align-items: center;
                    padding: 5px 20px;
                    width: 100%;
                    h2{
                        font-weight: 400;
                        color: rgb(93, 91, 91);
                    }
                    p{
                        color: gray;
                        font-size: 14px;
                        padding-left: 10px;
                    }
                }
            }
            .item6{
                width: 32%;
                // border-radius: 24px;
                // background: rgb(254,219,142);
                // background: linear-gradient(180deg, rgba(254,219,142,1) 31%, rgba(255,255,255,1) 94%);
                .pic-1{
                   // background: url(../../assets/image/dome.jpg);
                    background-position: center;
                    background-size: cover;
                    width: 355px;
                    height: 250px;
                    border-radius: 25px;
                }
                .h-detail{
                    padding: 0px 20px;
                    h3{
                        color: rgb(93, 91, 91);
                        font-weight: 500;
                        margin-bottom: 10px;
                    }
                    p{
                        font-size: 14px;
                        color: gray;
                        margin: 0;
                    }
                }
                .fare{
                    display: flex;
                    align-items: center;
                    padding: 5px 20px;
                    width: 100%;
                    h2{
                        font-weight: 400;
                        color: rgb(93, 91, 91);
                    }
                    p{
                        color: gray;
                        font-size: 14px;
                        padding-left: 10px;
                    }
                }
            }
        }
        
    } 

    .part2{
        width: 100%;
        text-align: center;
        .book{
            //position: relative;
            // top: 40px;
            // left: 160px;
            height: 45px;
            width: 10%;
            margin-top: 10px;
            border: none;
            background-color:#144866;
            border-radius: 40px;
            font-size: 18px;
            color:#fff;
            cursor: pointer;
        }
        // .book:hover{
        //     background-color: #2779a7;
        //     color: #fff;
        // }
    }
    .book-1{
        position: relative;
        height: 30px;
        width: 35%;
        margin-top: 10px;
        border: none;
        background-color:#000;
        border-radius: 10px;
        font-size: 16px;
        color:#fff;
        cursor: pointer;
           a{
            text-decoration: none;
            color: #fff;
            font-weight: 500;
            font-size: 12px;
           }
    } 
    hr{
        border: 2px solid #000;
        margin-top: 35px;
        margin-bottom: 35px;
    }  
    
}
}
}

@media screen and (max-width:390px){
    .booking{
        padding-bottom: 10px ;
    .container-l{
        background-color: #fff;
        padding: 20px ;
        padding-top: 50px ;
        //padding-bottom: 120px;
       
       
        
        
       .container{
          width: 100% ;
       
           .title{
               width: 100%;
               display: flex;
               flex-direction: row;
               justify-content: space-between;
               padding-bottom: 40px;
               .part1{
                   width: 100%;
                   h1{
                       font-size: 25px;
                       font-weight: 600;
                       margin: 0%;
                   }
                   p{
                       color: gray;
                       font-size: 12px;
                   }
                   
               }
               
           }
           .card-second-2{
            width: 100%;
               .m-card{
                   display: flex;
                   flex-direction: row;
                   justify-content: space-between;
                   flex-wrap: wrap;
                   width: 100% !important;
                   padding-bottom: 25px;
                   .item1{
                       width: 48%;
                       border-radius: 24px;
                       margin-bottom: 10px ;
                 
                       .pic-1{
                           background: url(../../assets/image/dome_2.png);
                           background-position: center;
                           background-size: cover;
                           width: 160px ;
                           height: 150px;
                           border-radius: 10px;
                       }
                       .h-detail{
                           padding: 5px;
                           h3{
                               color: rgb(93, 91, 91);
                               font-weight: 500;
                               margin-bottom: 7px ;
                               margin: 0px;
                               font-size: 14px;
                           }
                           p{
                               font-size: 10px;
                               color: gray;
                               margin: 0;
                           }
                       }
                       .fare{
                           display: flex;
                           align-items: center;
                           padding: 5px 20px;
                           width: 100%;
                           h2{
                               font-weight: 400;
                               color: rgb(93, 91, 91);
                           }
                           p{
                               color: gray;
                               font-size: 14px;
                               padding-left: 10px;
                           }
                       }
                   }
                   .item2{
                       width: 48%;
                       border-radius: 24px;
                       margin-bottom: 10px ;
                    
                       .pic-1{
                          background: url(../../assets/image/a-k-veedu.jpg);
                          background-position: center;
                          background-size: cover;
                          width: 160px ;
                          height: 150px;
                          border-radius: 10px;
                       }
                       .h-detail{
                        padding: 5px;
                        h3{
                            color: rgb(93, 91, 91);
                            font-weight: 500;
                            margin-bottom: 7px ;
                            margin: 0px;
                            font-size: 14px;
                        }
                        p{
                            font-size: 10px;
                            color: gray;
                            margin: 0;
                        }
                    }
                       .fare{
                           display: flex;
                           align-items: center;
                           padding: 5px 20px;
                           width: 100%;
                           h2{
                               font-weight: 400;
                               color: rgb(93, 91, 91);
                           }
                           p{
                               color: gray;
                               font-size: 14px;
                               padding-left: 10px;
                           }
                       }
                   }
                   .item3{
                       width: 48%;
                       border-radius: 24px;
                       margin-bottom: 10px ;
                  
                       .pic-1{
                          background: url(../../assets/image/drone_night.png);
                          background-position: center;
                          background-size: cover;
                          width: 160px ;
                          height: 150px;
                          border-radius: 10px;
                       }
                       .h-detail{
                        padding: 5px;
                        h3{
                            color: rgb(93, 91, 91);
                            font-weight: 500;
                            margin-bottom: 7px ;
                            margin: 0px;
                            font-size: 14px;
                        }
                        p{
                            font-size: 10px;
                            color: gray;
                            margin: 0;
                        }
                    }
                       .fare{
                           display: flex;
                           align-items: center;
                           padding: 5px 20px;
                           width: 100%;
                           h2{
                               font-weight: 400;
                               color: rgb(93, 91, 91);
                           }
                           p{
                               color: gray;
                               font-size: 14px;
                               padding-left: 10px;
                           }
                       }
                   
                   }
                   .item4{
                       width: 48%;
                       border-radius: 24px;
                       margin-bottom: 10px ;
                  
                       .pic-1{
                        background: url(../../assets/image/purple-1.JPG);
                           background-position: center;
                           background-size: cover;
                           width: 160px ;
                           height: 150px;
                           border-radius: 10px;
                       }
                       .h-detail{
                        padding: 5px;
                        h3{
                            color: rgb(93, 91, 91);
                            font-weight: 500;
                            margin-bottom: 7px ;
                            margin: 0px;
                            font-size: 14px;
                        }
                        p{
                            font-size: 10px;
                            color: gray;
                            margin: 0;
                        }
                    }
                       .fare{
                           display: flex;
                           align-items: center;
                           padding: 5px 20px;
                           width: 100%;
                           h2{
                               font-weight: 400;
                               color: rgb(93, 91, 91);
                           }
                           p{
                               color: gray;
                               font-size: 14px;
                               padding-left: 10px;
                           }
                       }
                   }
                   .item5{
                    width: 48%;
                    border-radius: 24px;
                    margin-bottom: 10px ;
                       // background: linear-gradient(180deg, rgba(254,219,142,1) 31%, rgba(255,255,255,1) 94%);
                       .pic-1{
                           background: url(../../assets/image/JAI01735-HDR-2.jpg);
                           background-position: center;
                           background-size: cover;
                           width: 160px ;
                           height: 150px;
                           border-radius: 10px;
                       }
                       .h-detail{
                        padding: 5px;
                        h3{
                            color: rgb(93, 91, 91);
                            font-weight: 500;
                            margin-bottom: 7px ;
                            margin: 0px;
                            font-size: 14px;
                        }
                        p{
                            font-size: 10px;
                            color: gray;
                            margin: 0;
                        }
                    }
                       .fare{
                           display: flex;
                           align-items: center;
                           padding: 5px 20px;
                           width: 100%;
                           h2{
                               font-weight: 400;
                               color: rgb(93, 91, 91);
                           }
                           p{
                               color: gray;
                               font-size: 14px;
                               padding-left: 10px;
                           }
                       }
                   }
                   .item6{
                    width: 48%;
                    border-radius: 24px;
                    margin-bottom: 10px ;
                       // background: rgb(254,219,142);
                       // background: linear-gradient(180deg, rgba(254,219,142,1) 31%, rgba(255,255,255,1) 94%);
                       .pic-1{
                           background: url(../../assets/image/JAI01660-HDR-2.jpg);
                           background-position: center;
                           background-size: cover;
                           width: 160px ;
                           height: 150px;
                           border-radius: 10px;
                       }
                       .h-detail{
                        padding: 5px;
                        h3{
                            color: rgb(93, 91, 91);
                            font-weight: 500;
                            margin-bottom: 7px ;
                            margin: 0px;
                            font-size: 14px;
                        }
                        p{
                            font-size: 10px;
                            color: gray;
                            margin: 0;
                        }
                    }
                       .fare{
                           display: flex;
                           align-items: center;
                           padding: 5px 20px;
                           width: 100%;
                           h2{
                               font-weight: 400;
                               color: rgb(93, 91, 91);
                           }
                           p{
                               color: gray;
                               font-size: 14px;
                               padding-left: 10px;
                           }
                       }
                   }
               }
               
               
           } 

           .part2{
            width: 100%;
            text-align: center;
            .book{
                //position: relative;
                // top: 40px;
                // left: 160px;
                height: 35px;
                width: 26%;
                margin-top: 10px;
                border: none;
                background-color:#144866;
                border-radius: 40px;
                font-size: 14px;
                color:#fff;
                cursor: pointer;
            }
            // .book:hover{
            //     background-color: #2779a7;
            //     color: #fff;
            // }
        }
           .book-1{
               position: relative;
               height: 30px;
               width: 35%;
               margin-top: 10px;
               border: none;
               background-color:#000;
               border-radius: 10px;
               font-size: 16px;
               color:#fff;
               cursor: pointer;
                  a{
                   text-decoration: none;
                   color: #fff;
                   font-weight: 500;
                   font-size: 12px;
                  }
           } 
           hr{
               border: 2px solid #000;
               margin-top: 35px;
               margin-bottom: 35px;
           }  
           
       }
       }
    }

  }

@media screen and (max-width:450px){
    .booking{
        padding-bottom: 10px ;
    .container-l{
        background-color: #fff;
        padding: 20px ;
        padding-top: 50px ;
        //padding-bottom: 120px;
       
       
        
        
       .container{
          width: 100% ;
       
           .title{
               width: 100%;
               display: flex;
               flex-direction: row;
               justify-content: space-between;
               padding-bottom: 20px;
               .part1{
                   width: 100%;
                   h1{
                       font-size: 25px !important;
                       font-weight: 600;
                       margin: 0%;
                   }
                   p{
                       color: gray;
                       font-size: 13px;
                   }
                   
               }
               
           }
           .card-second-2{
            width: 100%;
               .m-card{
                   display: flex;
                   flex-direction: row;
                   justify-content: space-between;
                   flex-wrap: wrap;
                   width: 100% ;
                   padding-bottom: 25px;
                   .item1{
                       width:100%;
                       border-radius: 24px;
                       margin-bottom: 10px ;
                       padding-bottom: 20px;
                       //padding-bottom: 0px !important;
                 
                       .pic-1{
                           background: url(../../assets/image/dome_2.png);
                           background-position: center;
                           background-size: cover;
                           width: 182px  ;
                           height: 250px;
                           border-radius: 10px;
                       }
                       .h-detail{
                           padding: 5px;
                           h3{
                               color: rgb(93, 91, 91);
                               font-weight: 600;
                               margin-bottom: 7px ;
                               margin: 0px;
                               font-size: 18px;
                           }
                           p{
                               font-size: 14px;
                               color: gray;
                               margin: 0;
                               padding-top: 5px;
                           }
                       }
                       .fare{
                           display: flex;
                           align-items: center;
                           padding: 5px 20px;
                           width: 100%;
                           h2{
                               font-weight: 400;
                               color: rgb(93, 91, 91);
                           }
                           p{
                               color: gray;
                               font-size: 14px;
                               padding-left: 10px;
                           }
                       }
                   }
                   .item2{
                       width: 100%;
                       border-radius: 24px;
                       margin-bottom: 10px ;
                       padding-bottom: 20px;
                    
                       .pic-1{
                          background: url(../../assets/image/a-k-veedu.jpg);
                          background-position: center;
                          background-size: cover;
                          width: 182px  ;
                          height: 250px;
                          border-radius: 10px;
                       }
                       .h-detail{
                        padding: 5px;
                        h3{
                            color: rgb(93, 91, 91);
                            font-weight: 600;
                            margin-bottom: 7px ;
                            margin: 0px;
                            font-size: 18px;
                        }
                        p{
                            font-size: 14px;
                            color: gray;
                            margin: 0;
                            padding-top: 5px;
                        }
                    }
                       .fare{
                           display: flex;
                           align-items: center;
                           padding: 5px 20px;
                           width: 100%;
                           h2{
                               font-weight: 400;
                               color: rgb(93, 91, 91);
                           }
                           p{
                               color: gray;
                               font-size: 14px;
                               padding-left: 10px;
                           }
                       }
                   }
                   .item3{
                       width: 100%;
                       border-radius: 24px;
                       margin-bottom: 10px ;
                       padding-bottom: 20px;
                  
                       .pic-1{
                          background: url(../../assets/image/drone_night.png);
                          background-position: center;
                          background-size: cover;
                          width: 182px  ;
                          height: 250px;
                          border-radius: 10px;
                       }
                       .h-detail{
                        padding: 5px;
                        h3{
                            color: rgb(93, 91, 91);
                            font-weight: 600;
                            margin-bottom: 7px ;
                            margin: 0px;
                            font-size: 18px;
                        }
                        p{
                            font-size: 14px;
                            color: gray;
                            margin: 0;
                            padding-top: 5px;
                        }
                    }
                       .fare{
                           display: flex;
                           align-items: center;
                           padding: 5px 20px;
                           width: 100%;
                           h2{
                               font-weight: 400;
                               color: rgb(93, 91, 91);
                           }
                           p{
                               color: gray;
                               font-size: 14px;
                               padding-left: 10px;
                           }
                       }
                   
                   }
                   .item4{
                       width: 100%;
                       border-radius: 24px;
                       margin-bottom: 10px ;
                       padding-bottom: 20px;
                  
                       .pic-1{
                        background: url(../../assets/image/purple-1.JPG);
                           background-position: center;
                           background-size: cover;
                           width: 182px ;
                           height: 250px;
                           border-radius: 10px;
                       }
                       .h-detail{
                        padding: 5px;
                        h3{
                            color: rgb(93, 91, 91);
                            font-weight: 600;
                            margin-bottom: 7px ;
                            margin: 0px;
                            font-size: 18px;
                        }
                        p{
                            font-size: 14px;
                            color: gray;
                            margin: 0;
                            padding-top: 5px;
                        }
                    }
                       .fare{
                           display: flex;
                           align-items: center;
                           padding: 5px 20px;
                           width: 100%;
                           h2{
                               font-weight: 400;
                               color: rgb(93, 91, 91);
                           }
                           p{
                               color: gray;
                               font-size: 14px;
                               padding-left: 10px;
                           }
                       }
                   }
                   .item5{
                    width: 100%;
                    border-radius: 24px;
                    margin-bottom: 10px ;
                    padding-bottom: 20px;
                       // background: linear-gradient(180deg, rgba(254,219,142,1) 31%, rgba(255,255,255,1) 94%);
                       .pic-1{
                           background: url(../../assets/image/JAI01735-HDR-2.jpg);
                           background-position: center;
                           background-size: cover;
                           width: 182px ;
                           height: 250px;
                           border-radius: 10px;
                       }
                       .h-detail{
                        padding: 5px;
                        h3{
                            color: rgb(93, 91, 91);
                            font-weight: 600;
                            margin-bottom: 7px ;
                            margin: 0px;
                            font-size: 18px;
                        }
                        p{
                            font-size: 14px;
                            color: gray;
                            margin: 0;
                            padding-top: 5px;
                        }
                    }
                       .fare{
                           display: flex;
                           align-items: center;
                           padding: 5px 20px;
                           width: 100%;
                           h2{
                               font-weight: 400;
                               color: rgb(93, 91, 91);
                           }
                           p{
                               color: gray;
                               font-size: 14px;
                               padding-left: 10px;
                           }
                       }
                   }
                   .item6{
                    width: 100%;
                    border-radius: 24px;
                    margin-bottom: 10px ;
                    padding-bottom: 20px;
                       // background: rgb(254,219,142);
                       // background: linear-gradient(180deg, rgba(254,219,142,1) 31%, rgba(255,255,255,1) 94%);
                       .pic-1{
                           background: url(../../assets/image/JAI01660-HDR-2.jpg);
                           background-position: center;
                           background-size: cover;
                           width: 182px ;
                           height: 250px;
                           border-radius: 10px;
                       }
                       .h-detail{
                        padding: 5px;
                        h3{
                            color: rgb(93, 91, 91);
                            font-weight: 600;
                            margin-bottom: 7px ;
                            margin: 0px;
                            font-size: 18px;
                        }
                        p{
                            font-size: 14px;
                            color: gray;
                            margin: 0;
                            padding-top: 5px;
                        }
                    }
                       .fare{
                           display: flex;
                           align-items: center;
                           padding: 5px 20px;
                           width: 100%;
                           h2{
                               font-weight: 400;
                               color: rgb(93, 91, 91);
                           }
                           p{
                               color: gray;
                               font-size: 14px;
                               padding-left: 10px;
                           }
                       }
                   }
               }
              
               
           } 

           .part2{
            width: 100%;
            text-align: center;
            .book{
                //position: relative;
                // top: 40px;
                // left: 160px;
                height: 35px;
                width: 26%;
                margin-top: 10px;
                border: none;
                background-color:#144866;
                border-radius: 40px;
                font-size: 14px;
                color:#fff;
                cursor: pointer;
            }
            // .book:hover{
            //     background-color: #2779a7;
            //     color: #fff;
            // }
        }
           .book-1{
               position: relative;
               height: 30px;
               width: 35%;
               margin-top: 10px;
               border: none;
               background-color:#000;
               border-radius: 10px;
               font-size: 16px;
               color:#fff;
               cursor: pointer;
                  a{
                   text-decoration: none;
                   color: #fff;
                   font-weight: 500;
                   font-size: 12px;
                  }
           } 
           hr{
               border: 2px solid #000;
               margin-top: 35px;
               margin-bottom: 35px;
           }  
           
       }
       }
    }

}


@media screen and (min-width: 700px) and (max-width: 950px){
    .booking{
        padding-bottom: 10px ;
    .container-l{
        background-color: #fff;
        padding: 20px ;
        padding-top: 50px ;
        //padding-bottom: 120px;
       
       
        
        
       .container{
          width: 100% ;
       
           .title{
               width: 100%;
               display: flex;
               flex-direction: row;
               justify-content: space-between;
               padding-bottom: 40px;
               .part1{
                   width: 100%;
                   h1{
                       font-size: 25px;
                       font-weight: 600;
                       margin: 0%;
                   }
                   p{
                       color: gray;
                       font-size: 12px;
                   }
                   
               }
               
           }
           .card-second-2{
            width: 100%;
               .m-card{
                   display: flex;
                   flex-direction: row;
                   justify-content: space-between;
                   flex-wrap: wrap;
                   width: 100% ;
                   padding-bottom: 25px;
                   .item1{
                       width: 48%;
                       border-radius: 24px;
                       margin-bottom: 10px ;
                 
                       .pic-1{
                           background: url(../../assets/image/dome_2.png);
                           background-position: center;
                           background-size: cover;
                           width: 182px  ;
                           height: 250px;
                           border-radius: 10px;
                       }
                       .h-detail{
                           padding: 7px;
                           h3{
                               color: rgb(93, 91, 91);
                               font-weight: 500;
                               margin-bottom: 7px ;
                               margin: 0px;
                               font-size: 14px;
                           }
                           p{
                               font-size: 12px;
                               color: gray;
                               margin: 0;
                           }
                       }
                       .fare{
                           display: flex;
                           align-items: center;
                           padding: 5px 20px;
                           width: 100%;
                           h2{
                               font-weight: 400;
                               color: rgb(93, 91, 91);
                           }
                           p{
                               color: gray;
                               font-size: 14px;
                               padding-left: 10px;
                           }
                       }
                   }
                   .item2{
                       width: 48%;
                       border-radius: 24px;
                       margin-bottom: 10px ;
                    
                       .pic-1{
                          background: url(../../assets/image/a-k-veedu.jpg);
                          background-position: center;
                          background-size: cover;
                          width: 182px  ;
                          height: 250px;
                          border-radius: 10px;
                       }
                       .h-detail{
                        padding: 7px;
                        h3{
                            color: rgb(93, 91, 91);
                            font-weight: 500;
                            margin-bottom: 7px ;
                            margin: 0px;
                            font-size: 16px;
                        }
                        p{
                            font-size: 12px;
                            color: gray;
                            margin: 0;
                        }
                    }
                       .fare{
                           display: flex;
                           align-items: center;
                           padding: 5px 20px;
                           width: 100%;
                           h2{
                               font-weight: 400;
                               color: rgb(93, 91, 91);
                           }
                           p{
                               color: gray;
                               font-size: 14px;
                               padding-left: 10px;
                           }
                       }
                   }
                   .item3{
                       width: 48%;
                       border-radius: 24px;
                       margin-bottom: 10px ;
                  
                       .pic-1{
                          background: url(../../assets/image/drone_night.png);
                          background-position: center;
                          background-size: cover;
                          width: 182px  ;
                          height: 250px;
                          border-radius: 10px;
                       }
                       .h-detail{
                        padding: 7px;
                        h3{
                            color: rgb(93, 91, 91);
                            font-weight: 500;
                            margin-bottom: 7px !important;
                            margin: 0px;
                            font-size: 16px;
                        }
                        p{
                            font-size: 12px;
                            color: gray;
                            margin: 0;
                        }
                    }
                       .fare{
                           display: flex;
                           align-items: center;
                           padding: 5px 20px;
                           width: 100%;
                           h2{
                               font-weight: 400;
                               color: rgb(93, 91, 91);
                           }
                           p{
                               color: gray;
                               font-size: 14px;
                               padding-left: 10px;
                           }
                       }
                   
                   }
                   .item4{
                       width: 48%;
                       border-radius: 24px;
                       margin-bottom: 10px ;
                  
                       .pic-1{
                        background: url(../../assets/image/purple-1.JPG);
                           background-position: center;
                           background-size: cover;
                           width: 182px ;
                           height: 250px;
                           border-radius: 10px;
                       }
                       .h-detail{
                        padding: 7px;
                        h3{
                            color: rgb(93, 91, 91);
                            font-weight: 500;
                            margin-bottom: 7px ;
                            margin: 0px;
                            font-size: 16px;
                        }
                        p{
                            font-size: 12px;
                            color: gray;
                            margin: 0;
                        }
                    }
                       .fare{
                           display: flex;
                           align-items: center;
                           padding: 5px 20px;
                           width: 100%;
                           h2{
                               font-weight: 400;
                               color: rgb(93, 91, 91);
                           }
                           p{
                               color: gray;
                               font-size: 14px;
                               padding-left: 10px;
                           }
                       }
                   }
                   .item5{
                    width: 48%;
                    border-radius: 24px;
                    margin-bottom: 10px ;
                       // background: linear-gradient(180deg, rgba(254,219,142,1) 31%, rgba(255,255,255,1) 94%);
                       .pic-1{
                           background: url(../../assets/image/a-frame.jpg);
                           background-position: center;
                           background-size: cover;
                           width: 182px ;
                           height: 250px;
                           border-radius: 10px;
                       }
                       .h-detail{
                        padding: 7px;
                        h3{
                            color: rgb(93, 91, 91);
                            font-weight: 500;
                            margin-bottom: 7px ;
                            margin: 0px;
                            font-size: 16px;
                        }
                        p{
                            font-size: 12px;
                            color: gray;
                            margin: 0;
                        }
                    }
                       .fare{
                           display: flex;
                           align-items: center;
                           padding: 5px 20px;
                           width: 100%;
                           h2{
                               font-weight: 400;
                               color: rgb(93, 91, 91);
                           }
                           p{
                               color: gray;
                               font-size: 14px;
                               padding-left: 10px;
                           }
                       }
                   }
                   .item6{
                    width: 48%;
                    border-radius: 24px;
                    margin-bottom: 10px ;
                       // background: rgb(254,219,142);
                       // background: linear-gradient(180deg, rgba(254,219,142,1) 31%, rgba(255,255,255,1) 94%);
                       .pic-1{
                           background: url(../../assets/image/cabin.jpg);
                           background-position: center;
                           background-size: cover;
                           width: 182px ;
                           height: 250px;
                           border-radius: 10px;
                       }
                       .h-detail{
                        padding: 7px;
                        h3{
                            color: rgb(93, 91, 91);
                            font-weight: 500;
                            margin-bottom: 7px ;
                            margin: 0px;
                            font-size: 16px;
                        }
                        p{
                            font-size: 12px;
                            color: gray;
                            margin: 0;
                        }
                    }
                       .fare{
                           display: flex;
                           align-items: center;
                           padding: 5px 20px;
                           width: 100%;
                           h2{
                               font-weight: 400;
                               color: rgb(93, 91, 91);
                           }
                           p{
                               color: gray;
                               font-size: 14px;
                               padding-left: 10px;
                           }
                       }
                   }
               }
              
               
           } 

           .part2{
            width: 100%;
            text-align: center;
            .book{
                //position: relative;
                // top: 40px;
                // left: 160px;
                height: 35px;
                width: 26%;
                margin-top: 10px;
                border: none;
                background-color:#144866;
                border-radius: 40px;
                font-size: 14px;
                color:#fff;
                cursor: pointer;
            }
            // .book:hover{
            //     background-color: #2779a7;
            //     color: #fff;
            // }
        }
           .book-1{
               position: relative;
               height: 30px;
               width: 35%;
               margin-top: 10px;
               border: none;
               background-color:#000;
               border-radius: 10px;
               font-size: 16px;
               color:#fff;
               cursor: pointer;
                  a{
                   text-decoration: none;
                   color: #fff;
                   font-weight: 500;
                   font-size: 12px;
                  }
           } 
           hr{
               border: 2px solid #000;
               margin-top: 35px;
               margin-bottom: 35px;
           }  
           
       }
       }
    }

  }

@media screen and (max-width:992px){

    .container{
        max-width: 960px;
    }


  }
    
  

@media screen and (min-width:1200px){
    .container{
        max-width: 1140px;
    }
    
}
 

@media screen and (max-width:1300px){
    .container{
        max-width: 1140px;
    }
   
       
}

@media screen and (min-width:1200px){
    .container{
        max-width: 1140px;
    }
           
}

