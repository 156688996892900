.mr-top{
    margin-top: 130px !important;
    padding-top: 10px !important;
}
.container-box{
    width: 100vw;
}
.container-I{
    background-color: rgb(239, 238, 238);
    padding-top: 0px;
    padding-bottom: 60px;
    padding-left: 125px;
    padding-right: 125px;
    .styles-module_wrapper__1I_qj{
        margin-top: 0px;
        padding-top: 100px !important;
    }
    .styles-module_close__2I1sI{
       //margin-top: 70px;
    }
    .container{
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        
        .list-d{
            ul{
                display: flex;
                gap: 15px;
                padding: 0px;
                li{
                    list-style: none;
                    display: flex;
                    align-items: center;
                    
                    p{
                        font-weight: 500;
                    }
                    .R-arrow{
                        width: 10px;
                        height: 10px;
                        margin-left: 10px;
                    }
                }
            }
        }
        .list-container{
            border-radius: 12px;
            background-color:#fff;
            width: 100%;
            margin-top: 50px;
            
            .list-flex{
                display:flex;                
                width: 100%;
                padding: 20px 20px 0px ;
                gap: 16px !important;
                .picture-1{
                    width: 50%;
                    .p-one{
                        //background:url(../../assets/image/dome_2.png) ;
                        background-position: center;
                        background-size: cover;
                        width: 100% !important;
                        height: 415px;
                        border-radius: 24px;
                        object-fit: cover;
                        cursor: pointer;
                    
                        
                    }
                    .p-one-1{
                        background:url(../../assets/image/a-veedu.jpg) ;
                        background-position: center;
                        background-size: cover;
                        width: 100% !important;
                        height: 415px;
                        border-radius: 24px;
                        cursor: pointer;
                    
                        
                    }
                    .p-one-2{
                        background:url(../../assets/image/drone_night.png) ;
                        background-position: center;
                        background-size: cover;
                        width: 100% !important;
                        height: 415px;
                        border-radius: 24px;
                        cursor: pointer;
                        
                    }
                    .p-one-3{
                        background:url(../../assets/image/purple.JPG) ;
                        background-position: center;
                        background-size: cover;
                        width: 100% !important;
                        height: 415px;
                        border-radius: 24px;
                        cursor: pointer;
                        
                    }

                }
                .picture-2{
                    display: flex;
                    flex-wrap: wrap;
                    gap: 15px;
                    width: 50%;

                    .p-two{
                        //background:url(../../assets/image/dome.png) ;
                        background-position: center;
                        background-size: cover;
                        width: 48.5% !important;
                        height: 200px;
                        border-radius: 12px;
                        object-fit: cover;
                        cursor: pointer;
                    }
                    .p-two-1{
                        background:url(../../assets/image/x-stay.jpg) ;
                        background-position: center;
                        background-size: cover;
                        width: 48.5%!important;
                        height: 200px;
                        border-radius: 12px;
                        cursor: pointer;
                    }
                    .p-two-2{
                        background:url(../../assets/image/Bedroom2__.png) ;
                        background-position: center;
                        background-size: cover;
                        width: 48.5%!important;
                        height: 200px;
                        border-radius: 12px;
                        cursor: pointer;
                    }
                    .p-two-3{
                        background:url(../../assets/image/purple-1.JPG) ;
                        background-position: center;
                        background-size: cover;
                        width: 48.5%!important;
                        height: 200px;
                        border-radius: 12px;
                        cursor: pointer;
                    }
                    .p-three{
                        //background:url(../../assets/image/bed-1.jpg) ;
                        background-position: center;
                        background-size: cover;
                        width: 48.5% !important;
                        height: 200px;
                        border-radius: 12px;
                        object-fit: cover;
                        cursor: pointer;
                    }
                    .p-three-1{
                        background:url(../../assets/image/1W3A1999.jpg) ;
                        background-position: center;
                        background-size: cover;
                        width: 48.5%!important;
                        height: 200px;
                        border-radius: 12px;
                        cursor: pointer;
                    }
                    .p-three-3{
                        background:url(../../assets/image/IMG_7104.JPG) ;
                        background-position: center;
                        background-size: cover;
                        width: 48.5%!important;
                        height: 200px;
                        border-radius: 12px;
                        cursor: pointer;
                    }
                    .p-three-2{
                        background:url(../../assets/image/dine-in.png) ;
                        background-position: center;
                        background-size: cover;
                        width: 48.5%!important;
                        height: 200px;
                        border-radius: 12px;
                        cursor: pointer;
                    }
                    .p-four{
                        //background-image:url(../../assets/image/bed-2.jpg) ;
                        background-position: center;
                        background-size: cover;
                        width: 48.5% !important;
                        height: 200px;
                        border-radius: 12px;
                        object-fit: cover;
                        cursor: pointer;
                    }
                    .p-four-1{
                        background-image:url(../../assets/image/1W3A1678.jpg) ;
                        background-position: center;
                        background-size: cover;
                        width: 48.5%!important;
                        height: 200px;
                        border-radius: 12px;
                        cursor: pointer;
                    }
                    .p-four-2{
                        background-image:url(../../assets/image/Living_room.png) ;
                        background-position: center;
                        background-size: cover;
                        width: 48.5%!important;
                        height: 200px;
                        border-radius: 12px;
                        cursor: pointer; 
                    }
                    .p-four-3{
                        background-image:url(../../assets/image/IMG_7061.JPG) ;
                        background-position: center;
                        background-size: cover;
                        width: 48.5% !important;
                        height: 200px;
                        border-radius: 12px;
                        cursor: pointer; 
                    }
                    .p-map{
                       //background:url(../../assets/image/map.jpeg) ;
                        width: 48.5% !important;
                        height: 200px;
                        border-radius: 12px;
                        iframe{
                            border-radius: 12px;
                            width: 100% !important;
                            height: 200px;
                        }
                        
                    }
                }

            }
            .list-flex{
                display:flex;
                width: 100%;
                padding: 20px 20px 0px ;
                gap: 16px;
                .r-msg{
                    background-color: rgb(239, 238, 238);
                    padding: 15px;
                    width: 50%;
                    height: 280px;
                    border-radius: 12px;

                    h1{
                        margin: 0;
                        font-weight:bolder;
                        color: #474747;
                    }
                    p{
                        font-size: 18px;
                        color: gray;
                        margin: 3px;
                        text-align: justify;
                    }
                    h4{
                        font-size: 20px;
                        font-weight: 400;
                        margin-top: 6px;
                        margin-bottom: 10px;
                        color: rgb(41, 40, 40);
                    }
                }
            }
            .pay{
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border: 1px solid rgb(204, 201, 201);
                border-radius: 12px;
                

                .count-d{
                    display: flex;
                    border: 1px solid black;
                    width: 90%;
                    padding: 20px;
                    align-items: center;
                    border-radius: 12px;
                    justify-content: space-between;
                    
                    .column-1{
                        width: 28%;
                        border-right: 1px solid #000;
                        label{
                            font-size: 20px;
                        }
                        .from-control{
                            display: block;
                            width: 100%;
                            height: calc(1em + 1rem + 4px);
                            padding: 1rem 0.5rem;
                            font-size: 22px;
                            font-weight:600;
                            line-height: 1.5;
                            color: #000;
                            outline: none;
                            border: none;
                            align-items: center;
                        }

                    }
                    .column-2{
                        width: 28%;
                        border-right: 1px solid #000;
                        label{
                            font-size: 20px;
                        }
                        .from-control{
                            display: block;
                            width: 100%;
                            height: calc(1em + 1rem + 4px);
                            padding: 1rem 0.5rem;
                            font-size: 22px;
                            font-weight: 600;
                            line-height: 1.5;
                            color: #000;
                            outline: none;
                            border: none;
                            align-items: center;
                        }

                    }
                    .column-3{
                        width: 33%;
                        
                        .point-1{
                            display: flex;
                            gap: 10px;
                            height: 20px;
                            align-items: center;
                            padding-bottom: 15px;
                            p{
                                font-size: 20px;
                            }
                        }
                        .point-2{
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            height: 20px;
                            padding-top: 10px;
                            .room{
                                width: 20px;
                                height: 20px;
                            }
                            p{
                                font-size: 20px;
                            }
                        }
                        


                    }
                    
                }
                .amount{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 90%;
                    padding-top: 20px;
                    .pay-fare{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 40%;
                        
                        .total{
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            height: 30px;
                            
                            h2{
                                font-size: 30px;
                            }
                            p{
                                color: gray;
                            }

                        }
                        .tax{
                            color: gray;
                            height: 20px;
                            align-items: center;
                            p{
                                margin: 0px;
                            }
                        }
                    }
                    .book-btn{
                        display: flex;
                        width: 40%;
                        justify-content: flex-start;
                        .book{
                            height: 5vh;
                            width: 80%;
                            margin-top: 10px;
                            border: none;
                            background-color:#144866;
                            border-radius: 12px;
                            font-size: 16px;
                            color:#fff;
                            cursor: pointer;
                            a{
                                text-decoration: none;
                                color: #fff;
                            } 
                    
                        }
               
                    }
            
                }
            }
            .mr-bottom{
                padding-bottom: 100px !important;
            }
            .amenities{
                width: 100%;
                margin-top: 20px;
                padding: 20px;
                //padding-bottom: 0px;
               .detail-card{
                width: 100%;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px;
                box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 1px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
                .detail-img{
                  width: 49%; 
                  img{
                    background-position: center;
                    background-size: cover;
                    width: 100%;
                    height: 300px;
                    border-radius: 10px;
                    object-fit: cover;
                  } 

                  .container-s{
                    width: 100%;
                    //height: 680px !important;
                    margin-right: auto;
                    margin-left: auto;
                    .content-slick {
                        text-align: center;
                        margin-bottom: 0px;
                  
                    .container-slick-home-1{
                        margin: 0 auto;
                        width: 99.9% !important;
                        position: relative;
.Left-arrow{
    position: absolute;
    width: 10px;
    height: 35px;
    z-index: 100;
    top: 140px;
    left: 15px;
}
.Right-arrow{
    position: absolute;
    width: 10px;
    height: 35px;
    z-index: 100;
    top: 140px;
    right: 15px;
}
.slick-prev{
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 100px;
    height: 250px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background:transparent;
    left: 0px;
    z-index: 1;
}
.slick-next{
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 100px;
    height: 250px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background:transparent;
    right: 0px;
    z-index: 1;
}
                        .box-slick-2{
                            display: flex !important;
                            flex-direction: row;
                            width: 100% !important;
                            //margin-left: 10px;
                            //height: 100vh;
                            //border-radius: 20px;
                            align-items: center;
                            justify-content: space-between;
                            background-color: #fff;
                            object-fit: cover;
                            //margin-bottom: 60px; 
                            .carousel-img-1{
                                background-position: center;
                                background-size: cover;
                                //height: 100vh;
                                width: 100%;
                            }
        }
        
    
      
    
        
    }
}
}
                }
                .details-contents{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    width: 49%;
                    h3{
                        color: #121511;
                        font-size: 34px;
                        margin-top: 0px;
                    }
                    .amenties-list{
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        border-bottom: 2px solid #D3D4D3;
                        padding-bottom: 15px;
                        .a-list{
                            width: 50%;
                            display: flex;
                            align-items: center;
                            padding: 10px 0px;
                            justify-content: flex-start;
                            gap: 10px;
                            img{

                            width: 35px;
                            height: 35px;
                            margin-right: 10px;

                            }
                            .house-img{
                                width: 40px;
                                height: 40px;
      
                            }
                            p{
                                color: #6e706e;
                                margin: 0px;
                                font-size: 20px;
                            }
                        }

                        span{
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            img{
                                width: 20px;
                                height: 20px;
                            }
                            p{
                                color: #EFBB1A;
                                margin: 5px;
                                
                            }
                        }
                    }
                    
                        
                        
                   
                    button{
                        border: none;
                        background-color: #fff;
                        text-decoration: underline;
                        text-align: left;
                        margin-top: 15px;
                        font-size: 16px;
                        color: #6e706e;
                        cursor: pointer;
                    }

                }

               }
               .book-btn-card{
              display: block;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #144866;
                color: #ffffff;
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
                //padding: 10px 400px 10px 400px;
                 height: 50px;
                
                
                width: 100%;
                a{
                    text-decoration: none;
                    font-size: 20px;
                font-weight: 600;
                color: #fff;
                }
               
               }
            }

        }

    }

}
@media screen and (max-width:390px){
    .container-I{
        background-color: rgb(239, 238, 238);
        padding-top: 80px;
        padding-bottom: 60px ;
        padding-left: 15px ;
        padding-right: 15px ;
        margin-top: 30px;
    
        .container{
            width: 100% ;
    
            .list-container{
                .list-flex{
                    padding: 10px ;
                    gap: 8px  !important;
                    .picture-1{
    
                        .p-one{
                            width: 160px ;
                            height: 160px ;
                            border-radius: 6px ;
                        }
                        .p-one-1{
                            width: 160px ;
                            height: 160px ;
                            border-radius: 6px ;
                        }
                        .p-one-2{
                            width: 160px ;
                            height: 160px ;
                            border-radius: 6px ;
                        }
                        .p-one-3{
                            width: 160px ;
                            height: 160px ;
                            border-radius: 6px ;
                        }
                    }
                    .picture-2{
                        gap: 4px;
                        .p-two{
                           
                            border-radius: 6px ;
                        }
                        .p-three{
                           
                            border-radius: 6px ;
                        }
                        .p-four{
                            
                            border-radius: 6px ;
                        }
                        .p-two-1{
                            
                            border-radius: 6px ;
                        }
                        .p-three-1{
                            
                            border-radius: 6px ;
                        }
                        .p-four-1{
                            
                            border-radius: 6px ;
                        }
                        .p-two-2{
                           
                            border-radius: 6px ;
                        }
                        .p-three-2{
                            
                            border-radius: 6px ;
                        }
                        .p-four-2{
                           
                            border-radius: 6px !important;
                        }
                        .p-two-3{
                           
                            border-radius: 6px !important;
                        }
                        .p-three-3{
                            width: 76px ;
                            height: 76px ;
                            border-radius: 6px !important;
                        }
                        .p-four-3{
                            
                            border-radius: 6px !important;
                        }
                        .p-map{
                            
                           
                            border-radius: 6px !important;
                            iframe{
                              
                            border-radius: 6px !important; 
                            .place-card{
                                width: 10px;
                                height: 10px;
                            } 
                            }
                        }
                    }
                }
                .list-flex{
                    .r-msg{
                        background-color: rgb(239, 238, 238);
                        padding: 10px;
                        width: 50%;
                        height: 145px !important;
                        border-radius: 6px;
    
                        h1{
                            font-size: 10px;
                            margin: 0;
                            font-weight:bolder;
                            color: #474747;
                        }
                        p{
                            font-size: 8px;
                            color: gray;
                            margin: 3px;
                            text-align: justify;
                        }
                        h4{
                            font-size: 8px;
                            font-weight: 400;
                            margin-top: 6px;
                            margin-bottom: 10px;
                            color: rgb(41, 40, 40);
                        }
                    }
                    .pay{
                        width: 50%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid rgb(204, 201, 201);
                        border-radius: 12px;
                        
        
                        .count-d{
                            display: flex;
                            border: 1px solid black;
                            width: 90%;
                            padding: 5px !important;
                            align-items: center;
                            border-radius: 12px;
                            justify-content: space-between;
                            
                            .column-1{
                                width: 28% !important;
                                border-right: 1px solid #000;
                                label{
                                    font-size: 7px !important;
                                    display: block;
                                }
                                .from-control{
                                    display: block;
                                    width: 100%;
                                    height: calc(1em + 1rem + 4px);
                                    padding: 5px 0px;
                                    font-size: 10px !important;
                                    font-weight:600;
                                    line-height: 1.5;
                                    color: #000;
                                    outline: none;
                                    border: none;
                                    align-items: center;
                                }
        
                            }
                            .column-2{
                                width: 30% !important;
                                border-right: 1px solid #000;
                                label{
                                    font-size: 7px !important;
                                    width: 100% !important;
                                    display: block;
                                }
                                .from-control{
                                    display: block;
                                    width: 100%;
                                    height: calc(1em + 1rem + 4px);
                                    padding:5px 0px;
                                    font-size: 10px !important;
                                    font-weight: 600;
                                    line-height: 1.5;
                                    color: #000;
                                    outline: none;
                                    border: none;
                                    align-items: center;
                                }
        
                            }
                            .column-3{
                                width: 38% !important;
                                
                                .point-1{
                                    display: flex;
                                    gap: 5px !important;
                                    height: 20px;
                                    align-items: center;
                                    padding-bottom: 7px;
                                    .room{
                                        width: 15px !important;
                                        height: 15px !important;
                                    }
                                    p{
                                        font-size: 7px !important;
                                    }
                                }
                                .point-2{
                                    display: flex;
                                    align-items: center;
                                    gap: 5px !important;
                                    height: 20px;
                                    padding-top: 10px;
                                    .room{
                                        width: 15px !important;
                                        height: 15px !important;
                                    }
                                    p{
                                        font-size: 7px !important;
                                    }
                                }
                                
        
        
                            }
                            
                        }
                       
                    }
                }
                .amenities{
                    width: 100%;
                    margin-top: 0px;
                    padding: 10px;
                    //padding-bottom: 0px;
                   .detail-card{
                    width: 100%;
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 10px;
                    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 1px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
                    .detail-img{
                      width: 49%; 
                      img{
                        background-position: center;
                        background-size: cover;
                        width: 100%;
                        height: 120px;
                        border-radius: 6px;
                        object-fit: cover;
                      } 
                    }
                    .details-contents{
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        width: 49%;
                        h3{
                            color: #121511;
                            font-size: 15px;
                            margin-top: 0px;
                            margin-bottom: 4px;
                        }
                        .amenties-list{
                            width: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            border-bottom: 2px solid #D3D4D3;
                            padding-bottom: 0px;
                            .a-list{
                                width: 50%;
                                display: flex;
                                align-items: center;
                                padding: 5px 0px;
                                justify-content: flex-start;
                                gap: 5px;
                                img{
    
                                width: 13px;
                                height: 13px;
                                margin-right: 5px;
    
                                }
                                .house-img{
                                    width: 18px;
                                    height: 18px;
          
                                }
                                p{
                                    color: #6e706e;
                                    margin: 0px;
                                    font-size: 8px;
                                }
                            }
    
                            span{
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                img{
                                    width: 10px;
                                    height: 10px;
                                }
                                p{
                                    color: #EFBB1A;
                                    margin: 5px;
                                    font-size: 8px;
                                    
                                }
                            }
                        }
                        
                            
                            
                       
                        
                        button{
                            border: none;
                            background-color: #fff;
                            text-decoration: underline;
                            text-align: left;
                            margin-top: 10px !important;
                            font-size: 12px !important;
                            color: #6e706e;
                            cursor: pointer !important;
                        }
    
                    }
    
                   }
                   .book-btn-card{
                    display: block;
                    border: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #1f6085;
                    color: #ffffff;
                    border-bottom-left-radius: 12px;
                    border-bottom-right-radius: 12px;
                    
                     height: 40px !important;
                    font-size: 14px !important;
                    font-weight: 600;
                    text-decoration: none;
                    cursor: pointer;
                    a{
                        text-decoration: none;
                        font-size: 14px !important;
                    font-weight: 600;
                    color: #fff;
                    }
                   }
                }
            }
        }
       
    }
    
      }
@media screen and (max-width:450px){
.container-I{
    background-color: rgb(239, 238, 238);
    padding-top: 80px;
    padding-bottom: 60px ;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin-top: 30px !important;

    .container{
        width: 100% !important;

        .list-container{
            margin-top: 10px !important;
            .list-flex{
                padding: 10px ;
                gap: 8px !important;
                .picture-1{
                    width: 100%;
                    .p-one{
                        width: 180px  ;
                        height: 270px ;
                        border-radius: 6px !important;
                    }
                    .p-one-1{
                        width: 180px;
                        height: 270px ;
                        border-radius: 6px !important;
                    }
                    .p-one-2{
                        width: 180px ;
                        height: 270px ;
                        border-radius: 6px !important;
                    }
                    .p-one-3{
                        width: 180px ;
                        height: 270px ;
                        border-radius: 6px !important;
                    }
                }
                .picture-2{
                    width: 100%;
                    gap: 8px !important;
                    .p-two{
                        height: 130px ;
                        border-radius: 6px ;
                    }
                    .p-three{
                        height: 130px ;
                        border-radius: 6px ;
                    }
                    .p-four{
                        height: 130px ;
                        border-radius: 6px ;
                    }
                    .p-two-1{
                        height: 130px ;
                        border-radius: 6px ;
                    }
                    .p-three-1{
                        height: 130px ;
                        border-radius: 6px ;
                    }
                    .p-four-1{
                        height: 130px ;
                        border-radius: 6px ;
                    }
                    .p-two-2{
                        height: 130px ;
                        border-radius: 6px ;
                    }
                    .p-three-2{
                        height: 130px ;
                        border-radius: 6px ;
                    }
                    .p-four-2{
                        height: 130px ;
                        border-radius: 6px !important;
                    }
                    .p-two-3{
                        height: 130px ;
                        border-radius: 6px !important;
                    }
                    .p-three-3{
                        
                        height: 130px ;
                        border-radius: 6px !important;
                    }
                    .p-four-3{
                        height: 130px ;
                        border-radius: 6px !important;
                    }
                    .p-map{
                        
                        height: 130px ;
                        border-radius: 6px !important;
                        iframe{
                            height: 130px ; 
                        border-radius: 6px !important; 
                        .place-card{
                            width: 10px;
                            height: 10px;
                        } 
                        }
                    }
                }
            }
            .mr-top-1{
                padding-top: 0px !important;
            }
            .list-flex{
                display: flex;
                flex-direction: column;
                .r-msg{
                    background-color: rgb(239, 238, 238);
                    padding: 10px;
                    width: 100%;
                    height: 150px;
                    border-radius: 6px;

                    h1{
                        font-size: 16px;
                        margin: 0;
                        font-weight:bolder;
                        color: #474747;
                    }
                    p{
                        font-size: 12px;
                        color: gray;
                        margin: 3px;
                        text-align: justify;
                    }
                    h4{
                        font-size: 12px;
                        font-weight: 400;
                        margin-top: 6px;
                        margin-bottom: 10px;
                        color: rgb(41, 40, 40);
                    }
                }
                .pay{
                    width: 100%;
                    height: 150px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid rgb(204, 201, 201);
                    border-radius: 12px;
                    
    
                    .count-d{
                        display: flex;
                        border: 1px solid black;
                        width: 90%;
                        padding: 15px !important;
                        align-items: center;
                        border-radius: 12px;
                        justify-content: space-between;
                        
                        .column-1{
                            width: 28% !important;
                            border-right: 1px solid #000;
                            label{
                                font-size: 12px !important;
                                display: block;
                                padding-top: 5px;
                            }
                            .from-control{
                                display: block;
                                width: 100%;
                                height: calc(1em + 1rem + 4px);
                                padding: 5px 0px;
                                font-size: 14px !important;
                                font-weight:600;
                                line-height: 1.5;
                                color: #000;
                                outline: none;
                                border: none;
                                align-items: center;
                            }
    
                        }
                        .column-2{
                            width: 30% !important;
                            border-right: 1px solid #000;
                            label{
                                font-size: 12px !important;
                                width: 100% !important;
                                display: block;
                                padding-top: 5px;
                            }
                            .from-control{
                                display: block;
                                width: 100%;
                                height: calc(1em + 1rem + 4px);
                                padding:5px 0px;
                                font-size: 14px !important;
                                font-weight: 600;
                                line-height: 1.5;
                                color: #000;
                                outline: none;
                                border: none;
                                align-items: center;
                                padding: 0px;
                            }
    
                        }
                        .column-3{
                            width: 30% !important;
                            
                            .point-1{
                                display: flex;
                                gap: 5px !important;
                                height: 20px;
                                align-items: center;
                                padding-bottom: 0px;
                                .room{
                                    width: 25px !important;
                                    height: 25px !important;
                                }
                                p{
                                    font-size: 12px !important;
                                }
                            }
                            .point-2{
                                display: flex;
                                align-items: center;
                                gap: 5px !important;
                                height: 20px;
                                padding-top: 0px;
                                .room{
                                    width: 25px !important;
                                    height: 25px !important;
                                }
                                p{
                                    font-size: 12px !important;
                                }
                            }
                            
    
    
                        }
                        
                    }
                   
                }
            }
            .amenities{
                width: 100%;
                margin-top: 0px;
                padding: 10px;
                //padding-bottom: 0px;
               .detail-card{
                width: 100%;
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px;
                box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 1px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
                .detail-img{
                  width: 49%; 
                  .container-s{
                    width: 100%;
                    //height: 680px !important;
                    margin-right: auto;
                    margin-left: auto;
                    .content-slick {
                        text-align: center;
                        margin-bottom: 0px;
                  
                    .container-slick-home-1{
                        margin: 0 auto;
                        width: 99.9% !important;
                        position: relative;
.Left-arrow{
    position: absolute;
    width: 10px;
    height: 25px;
    z-index: 100;
    top: 50px;
    left: 10px;
}
.Right-arrow{
    position: absolute;
    width: 10px;
    height: 25px;
    z-index: 100;
    top: 50px;
    right: 10px;
}
.slick-prev{
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 50px;
    height: 120px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background:transparent;
    left: 0px;
    z-index: 1;
}
.slick-next{
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 50px;
    height: 120px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background:transparent;
    right: 0px;
    z-index: 1;
}
                        .box-slick-2{
                            display: flex !important;
                            flex-direction: row;
                            width: 100% !important;
                            //margin-left: 10px;
                            //height: 100vh;
                            //border-radius: 20px;
                            align-items: center;
                            justify-content: space-between;
                            background-color: #fff;
                            object-fit: cover;
                            //margin-bottom: 60px; 
                            .carousel-img-1{
                                background-position: center;
                                background-size: cover;
                                //height: 100vh;
                                width: 100%;
                            }
        }
        
    
      
    
        
    }
}
} 
                  img{
                    background-position: center;
                    background-size: cover;
                    width: 100%;
                    height: 120px;
                    border-radius: 6px;
                    object-fit: cover;
                  } 
                }
                .details-contents{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    width: 49%;
                    h3{
                        color: #121511;
                        font-size: 15px;
                        margin-top: 0px;
                        margin-bottom: 4px;
                    }
                    .amenties-list{
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        border-bottom: 2px solid #D3D4D3;
                        padding-bottom: 0px;
                        .a-list{
                            width: 50%;
                            display: flex;
                            align-items: center;
                            padding: 5px 0px;
                            justify-content: flex-start;
                            gap: 5px;
                            img{

                            width: 13px;
                            height: 13px;
                            margin-right: 5px;

                            }
                            .house-img{
                                width: 18px;
                                height: 18px;
      
                            }
                            p{
                                color: #6e706e;
                                margin: 0px;
                                font-size: 8px;
                            }
                        }

                        span{
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            img{
                                width: 10px;
                                height: 10px;
                            }
                            p{
                                color: #EFBB1A;
                                margin: 5px;
                                font-size: 8px;
                                
                            }
                        }
                    }
                    
                        
                        
                   
                    
                    button{
                        border: none;
                        background-color: #fff;
                        text-decoration: underline;
                        text-align: left;
                        margin-top: 10px !important;
                        font-size: 12px !important;
                        color: #6e706e;
                    }

                }

               }
               .book-btn-card{
                display: block;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #1f6085;
                color: #ffffff;
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
                //padding: 10px 400px 10px 400px;
                 height: 40px !important;
                font-size: 20px;
                font-weight: 600;
                text-decoration: none;
                a{
                    text-decoration: none;
                    font-size: 16px;
                font-weight: 600;
                color: #fff;
                }
               }
            }
        }
    }
   
}

  }
  @media  (min-width: 700px) and (max-width: 950px){
    .container-I{
        background-color: rgb(239, 238, 238);
        padding-top: 80px;
        padding-bottom: 60px ;
        padding-left: 15px !important;
        padding-right: 15px !important;
        margin-top: 120px !important;
    
        .container{
            width: 100% !important;
    
            .list-container{
                margin-top: 25px !important;
                .list-flex{
                    padding: 10px ;
                    gap: 8px !important;
                    .picture-1{
    
                        .p-one{
                            width: 180px  ;
                            height: 250px ;
                            border-radius: 6px !important;
                        }
                        .p-one-1{
                            width: 180px;
                            height: 250px ;
                            border-radius: 6px !important;
                        }
                        .p-one-2{
                            width: 180px ;
                            height: 250px ;
                            border-radius: 6px !important;
                        }
                        .p-one-3{
                            width: 180px ;
                            height: 250px ;
                            border-radius: 6px !important;
                        }
                    }
                    .picture-2{
                        gap: 4px !important;
                        .p-two{
                            height: 120px ;
                            border-radius: 6px ;
                        }
                        .p-three{
                            height: 120px ;
                            border-radius: 6px ;
                        }
                        .p-four{
                            height: 120px ;
                            border-radius: 6px ;
                        }
                        .p-two-1{
                            height: 120px ;
                            border-radius: 6px ;
                        }
                        .p-three-1{
                            height: 120px ;
                            border-radius: 6px ;
                        }
                        .p-four-1{
                            height: 120px ;
                            border-radius: 6px ;
                        }
                        .p-two-2{
                            height: 120px ;
                            border-radius: 6px ;
                        }
                        .p-three-2{
                            height: 120px ;
                            border-radius: 6px ;
                        }
                        .p-four-2{
                            height: 120px ;
                            border-radius: 6px !important;
                        }
                        .p-two-3{
                            height: 120px ;
                            border-radius: 6px !important;
                        }
                        .p-three-3{
                            
                            height: 120px ;
                            border-radius: 6px !important;
                        }
                        .p-four-3{
                            height: 120px ;
                            border-radius: 6px !important;
                        }
                        .p-map{
                            
                            height: 120px ;
                            border-radius: 6px !important;
                            iframe{
                                height: 120px ; 
                            border-radius: 6px !important; 
                            .place-card{
                                width: 10px;
                                height: 10px;
                            } 
                            }
                        }
                    }
                }
                .list-flex{
                    .r-msg{
                        background-color: rgb(239, 238, 238);
                        padding: 10px;
                        width: 50%;
                        height: 145px;
                        border-radius: 6px;
    
                        h1{
                            font-size: 14px;
                            margin: 0;
                            font-weight:bolder;
                            color: #474747;
                        }
                        p{
                            font-size: 10px;
                            color: gray;
                            margin: 3px;
                            text-align: justify;
                        }
                        h4{
                            font-size: 10px;
                            font-weight: 400;
                            margin-top: 6px;
                            margin-bottom: 10px;
                            color: rgb(41, 40, 40);
                        }
                    }
                    .pay{
                        width: 50%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid rgb(204, 201, 201);
                        border-radius: 12px;
                        
        
                        .count-d{
                            display: flex;
                            border: 1px solid black;
                            width: 90%;
                            padding: 10px !important;
                            align-items: center;
                            border-radius: 12px;
                            justify-content: space-between;
                            
                            .column-1{
                                width: 28% !important;
                                border-right: 1px solid #000;
                                label{
                                    font-size: 10px !important;
                                    display: block;
                                    padding-top: 5px;
                                }
                                .from-control{
                                    display: block;
                                    width: 100%;
                                    height: calc(1em + 1rem + 4px);
                                    padding: 5px 0px;
                                    font-size: 12px !important;
                                    font-weight:600;
                                    line-height: 1.5;
                                    color: #000;
                                    outline: none;
                                    border: none;
                                    align-items: center;
                                }
        
                            }
                            .column-2{
                                width: 25% !important;
                                border-right: 1px solid #000;
                                label{
                                    font-size: 10px !important;
                                    width: 100% !important;
                                    display: block;
                                    padding-top: 5px;
                                }
                                .from-control{
                                    display: block;
                                    width: 100%;
                                    height: calc(1em + 1rem + 4px);
                                    padding:5px 0px;
                                    font-size: 12px !important;
                                    font-weight: 600;
                                    line-height: 1.5;
                                    color: #000;
                                    outline: none;
                                    border: none;
                                    align-items: center;
                                    padding: 0px;
                                }
        
                            }
                            .column-3{
                                width: 30% !important;
                                
                                .point-1{
                                    display: flex;
                                    gap: 5px !important;
                                    height: 20px;
                                    align-items: center;
                                    padding-bottom: 0px;
                                    .room{
                                        width: 20px !important;
                                        height: 20px !important;
                                    }
                                    p{
                                        font-size: 10px !important;
                                    }
                                }
                                .point-2{
                                    display: flex;
                                    align-items: center;
                                    gap: 5px !important;
                                    height: 20px;
                                    padding-top: 0px;
                                    .room{
                                        width: 20px !important;
                                        height: 20px !important;
                                    }
                                    p{
                                        font-size: 10px !important;
                                    }
                                }
                                
        
        
                            }
                            
                        }
                       
                    }
                }
                .amenities{
                    width: 100%;
                    margin-top: 0px;
                    padding: 10px;
                    //padding-bottom: 0px;
                   .detail-card{
                    width: 100%;
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 10px;
                    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 1px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
                    .detail-img{
                      width: 49%;
                      .container-s{
                        width: 100%;
                        //height: 680px !important;
                        margin-right: auto;
                        margin-left: auto;
                        .content-slick {
                            text-align: center;
                            margin-bottom: 0px;
                      
                        .container-slick-home-1{
                            margin: 0 auto;
                            width: 99.9% !important;
                            position: relative;
    .Left-arrow{
        position: absolute;
        width: 10px;
        height: 35px;
        z-index: 100;
        top: 75px;
        left: 15px;
    }
    .Right-arrow{
        position: absolute;
        width: 10px;
        height: 35px;
        z-index: 100;
        top: 75px;
        right: 15px;
    }
    .slick-prev{
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 50%;
        display: block;
        width: 100px;
        height: 250px;
        padding: 0;
        transform: translate(0, -50%);
        cursor: pointer;
        color: transparent;
        border: none;
        outline: none;
        background:transparent;
        left: 0px;
        z-index: 1;
    }
    .slick-next{
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 50%;
        display: block;
        width: 100px;
        height: 250px;
        padding: 0;
        transform: translate(0, -50%);
        cursor: pointer;
        color: transparent;
        border: none;
        outline: none;
        background:transparent;
        right: 0px;
        z-index: 1;
    }
                            .box-slick-2{
                                display: flex !important;
                                flex-direction: row;
                                width: 100% !important;
                                //margin-left: 10px;
                                //height: 100vh;
                                //border-radius: 20px;
                                align-items: center;
                                justify-content: space-between;
                                background-color: #fff;
                                object-fit: cover;
                                //margin-bottom: 60px; 
                                .carousel-img-1{
                                    background-position: center;
                                    background-size: cover;
                                    //height: 100vh;
                                    width: 100%;
                                }
            }
            
        
          
        
            
        }
    }
    } 
                      img{
                        background-position: center;
                        background-size: cover;
                        width: 100%;
                        height: 170px;
                        border-radius: 6px;
                        object-fit: cover;
                      } 
                    }
                    .details-contents{
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        width: 49%;
                        h3{
                            color: #121511;
                            font-size: 17px;
                            margin-top: 0px;
                            margin-bottom: 6px;
                        }
                        .amenties-list{
                            width: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            border-bottom: 2px solid #D3D4D3;
                            padding-bottom: 0px;
                            .a-list{
                                width: 50%;
                                display: flex;
                                align-items: center;
                                padding: 5px 0px;
                                justify-content: flex-start;
                                gap: 5px;
                                img{
    
                                width: 22px;
                                height: 22px;
                                margin-right: 5px;
    
                                }
                                .house-img{
                                    width: 25px;
                                    height: 25px;
          
                                }
                                p{
                                    color: #6e706e;
                                    margin: 0px;
                                    font-size: 10px;
                                }
                            }
    
                            span{
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                img{
                                    width: 14px;
                                    height: 14px;
                                }
                                p{
                                    color: #EFBB1A;
                                    margin: 5px;
                                    font-size: 10px;
                                    
                                }
                            }
                        }
                        
                            
                            
                       
                        
                        button{
                            border: none;
                            background-color: #fff;
                            text-decoration: underline;
                            text-align: left;
                            margin-top: 15px !important;
                            font-size: 14px !important;
                            color: #6e706e;
                        }
    
                    }
    
                   }
                   .book-btn-card{
                    display: block;
                    border: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #1f6085;
                    color: #ffffff;
                    border-bottom-left-radius: 6px;
                    border-bottom-right-radius: 6px;
                    //padding: 10px 400px 10px 400px;
                     height: 40px !important;
                    font-size: 20px;
                    font-weight: 600;
                    text-decoration: none;
                    a{
                        text-decoration: none;
                        font-size: 16px;
                    font-weight: 600;
                    color: #fff;
                    }
                   }
                }
            }
        }
       
    }
    
      }
@media screen and (max-width:992px){

    .container{
        max-width: 960px;
    }


  }
    
  

  @media screen and (max-width:1200px){
    .container{
        max-width: 1140px;
        .list-container{
            .list-flex{
                .picture-1{
                   
                }
                .picture-2{
                    
                }
            }
            .list-flex{
                .pay{
                    .count-d{
                        .column-3{
                            width: 34%;
                            p{
                                font-size: 18px !important;
                            }
                        }
                    }
                }
            }
        }
    }
   
}

@media screen and (min-width:1300px){
    .container{
        max-width: 1140px;
        .list-container{
            .list-flex{
                .picture-1{
                   
                }
                .picture-2{
                    
                }
            }
        }
    }
   
}

@media screen and (min-width:1400px){
    .container{
        max-width: 1140px;
        .list-container{
            .list-flex{
                .picture-1{
                    
                }
                .picture-2{
                    
                }
            }
        }
    }
   
    }