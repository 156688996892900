.container-contact{
    background-color: rgb(239, 238, 238);
    padding-top: 80px;
    padding-bottom: 60px;
    padding-left: 125px;
    padding-right: 125px;
    margin-top: 30px;
    
    .container{
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        display: flex;
        justify-content: center;
        .contact-container{
            border-radius: 12px;
            background-color:#fff;
            width: 80%;
            margin-top: 50px;
            height: 70%;
            padding: 40px;
            padding-bottom: 150px;
            .contact-header{
                text-align: center;
                border-bottom: 2px solid #C8CBD4;
                padding-bottom: 30px;
               
                .contact-top{
                    width: 100%;
                    h1{
                        font-size: 25px;
                        color: #121511;
                    }
                }
                .contact-number{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    .number-1{
                        width: 325px;
                      label{
                        color: #C8CBD4;
                      } 
                      .num-s{
                       color:#64656A;
                       margin: 5px;
                      } 
                    }
                }
            }

            .contact-content{
                margin-top: 40px;
                text-align: center;
                h1{
                    font-size: 25px;
                  color: #121511;
                }
                p{
                    color:#64656A;
                    margin: 5px;  
                    padding-bottom: 20px;
                }
                .contact-card{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    .contact-input{
                        width: 60%;
                        height: 40px;
                        border-radius: 6px;
                        padding:10px;
                        margin-bottom: 20px;
                        border: 2px solid #ccc;
                        &::placeholder{
                            color: #C8CBD4;
                        }
                    }
                     .btn-contact{
                    width: 140px;
                    height: 40px;
                    border-radius: 50px;
                    border: none;
                    background-color: #144866;
                    margin-top: 30px;
                    color:#fff;
                    cursor: pointer;
                }
                    textarea{
                        
                        width: 60%;
                        height: 130px;
                        padding: 10px;
                        box-sizing: border-box;
                        border: 2px solid #ccc;
                        border-radius: 6px;
                        background-color: #fff;
                        //font-size: 16px;
                        resize: none;
                        &::placeholder{
                            color: #C8CBD4;
                        }
                    }
                }
                .btn-contact{
                    width: 140px;
                    height: 40px;
                    border-radius: 50px;
                    border: none;
                    background-color: #144866;
                    margin-top: 30px;
                    color:#fff;
                }
                
            }
        }
    }
}

@media screen and (min-width: 700px) and (max-width: 950px){

    .mr-top{
        margin-top: 130px !important;
        //padding-top: 0px !important;
    }
    .container-contact{
        background-color: rgb(239, 238, 238);
        padding-top: 80px;
        padding-bottom: 60px;
        padding-left: 30px !important;
        padding-right: 30px !important;
        margin-top: 30px;
        
        .container{
            //max-width: 450px !important;
            padding-right: 0px !important;
            padding-left: 0px !important;
            margin-right: 0px !important;
            margin-left: 0px !important;
            display: flex;
            justify-content: center;
            .contact-container{
                border-radius: 12px;
                background-color:#fff;
                width: 500px ;
                margin-top: 20px !important;
                height: 70%;
                padding: 20px !important;
                padding-bottom: 150px;
                .contact-header{
                    text-align: center;
                    border-bottom: 2px solid #C8CBD4;
                    padding-bottom: 20px !important;
                   
                    .contact-top{
                        width: 100%;
                        h1{
                            font-size: 18px !important;
                            color: #121511;
                        }
                    }
                    .contact-number{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        .number-1{
                            width: 325px;
                          label{
                            color: #C8CBD4;
                            font-size: 12px;
                          } 
                          .num-s{
                           color:#64656A;
                           margin: 5px;
                           font-size: 12px;
                          } 
                        }
                    }
                }
    
                .contact-content{
                    margin-top: 30px !important;
                    text-align: center;
                    h1{
                        font-size: 18px !important;
                      color: #121511;
                    }
                    p{
                        color:#64656A;
                        margin: 5px;  
                        padding-bottom: 20px;
                        font-size: 11px;
                    }
                    .contact-card{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        .contact-input{
                            width: 70% !important;
                            height: 30px !important;
                            border-radius: 6px;
                            padding:10px;
                            margin-bottom: 20px;
                            font-size: 12px;
                            border: 2px solid #ccc;
                            &::placeholder{
                                color: #C8CBD4;
                                font-size: 12px;

                            }
                        }
                        textarea{
                            
                            width: 70% !important;
                            height: 100px !important;
                            padding: 10px;
                            box-sizing: border-box;
                            border: 2px solid #ccc;
                            border-radius: 6px;
                            background-color: #fff;
                            font-size: 12px;
                            //font-size: 16px;
                            resize: none;
                            &::placeholder{
                                color: #C8CBD4;
                                font-size: 12px;
                            }
                        }
                    }
                    .btn-contact{
                        width: 140px;
                        height: 35px;
                        border-radius: 50px;
                        border: none;
                        background-color: #144866;
                        margin-top: 30px;
                        color:#fff;
                        font-size: 14px;
                    }
                    
                }
            }
        }
    }  
}
@media screen and (max-width:450px){

    .mr-top{
        margin-top: 130px !important;
        //padding-top: 0px !important;
    }
    .container-contact{
        background-color: rgb(239, 238, 238);
        padding-top: 80px;
        padding-bottom: 60px;
        padding-left: 30px !important;
        padding-right: 30px !important;
        margin-top: 30px;
        
        .container{
            //max-width: 450px !important;
            padding-right: 0px !important;
            padding-left: 0px !important;
            margin-right: 0px !important;
            margin-left: 0px !important;
            display: flex;
            justify-content: center;
            .contact-container{
                border-radius: 12px;
                background-color:#fff;
                width: 375px !important;
                margin-top: 20px !important;
                height: 70%;
                padding: 20px !important;
                padding-bottom: 150px;
                .contact-header{
                    text-align: center;
                    border-bottom: 2px solid #C8CBD4;
                    padding-bottom: 20px !important;
                   
                    .contact-top{
                        width: 100%;
                        h1{
                            font-size: 18px !important;
                            color: #121511;
                        }
                    }
                    .contact-number{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        .number-1{
                            width: 325px;
                          label{
                            color: #C8CBD4;
                            font-size: 12px;
                          } 
                          .num-s{
                           color:#64656A;
                           margin: 5px;
                           font-size: 12px;
                          } 
                        }
                    }
                }
    
                .contact-content{
                    margin-top: 30px !important;
                    text-align: center;
                    h1{
                        font-size: 18px !important;
                      color: #121511;
                    }
                    p{
                        color:#64656A;
                        margin: 5px;  
                        padding-bottom: 20px;
                        font-size: 11px;
                    }
                    .contact-card{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        .contact-input{
                            width: 70% !important;
                            height: 34px !important;
                            border-radius: 6px;
                            padding:10px;
                            margin-bottom: 20px;
                            font-size: 14px;
                            border: 2px solid #ccc;
                            &::placeholder{
                                color: #C8CBD4;
                                font-size: 14px;

                            }
                        }
                        textarea{
                            
                            width: 70% !important;
                            height: 100px !important;
                            padding: 10px;
                            box-sizing: border-box;
                            border: 2px solid #ccc;
                            border-radius: 6px;
                            background-color: #fff;
                            font-size: 14px;
                            //font-size: 16px;
                            resize: none;
                            &::placeholder{
                                color: #C8CBD4;
                                font-size: 14px;
                            }
                        }
                    }
                    .btn-contact{
                        width: 140px;
                        height: 35px;
                        border-radius: 50px;
                        border: none;
                        background-color: #144866;
                        margin-top: 30px;
                        color:#fff;
                        font-size: 14px;
                    }
                    
                }
            }
        }
    }  
}