* {
  box-sizing: border-box;
}

.booking {
  width: 100%;
  padding-top: 0px !important;
}

.video-background {
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.video-background video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
  transform: translate(-50%, -50%);
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fcfcfc;
  text-align: center;
  .book-1 {
    position: relative;

    //height: 42px;
    width: 150px;
    //margin-top: 15px;
    border: none;
    background-color: #1f6085;
    border-radius: 8px;
    font-size: 20px;
    color: #fff !important;
    cursor: pointer;
    text-decoration: none !important;
    font-weight: 500;
    padding: 10px 15px;
    padding-top: 12px;
    a {
      text-decoration: none;
      color: #fff !important;
      font-weight: 600;
      margin-top: 2px;
    }
  }
}

.content h1 {
  font-size: 3em;
  margin-bottom: 0.5em;
}

.content p {
  font-size: 1.5em;
}

.container-b {
  display: flex;
  flex-direction: row;
  //position: absolute;
  bottom: 100px;
  width: 100%;

  .image {
    //background: url(../../assets/image/homepic.jpg)no-repeat;
    //background-position: center;
    //background-size: cover;
    width: 60%;
    height: 100vh;
    object-fit: cover;
  }
  .detail {
    width: 50%;
    background-color: #eef4f9;
    padding-left: 90px;
    padding-top: 220px;
    height: 100vh;
    h1 {
      width: 400px;
      font-size: 50px;
      margin-bottom: 20px;
      padding-top: 20px;
    }
    .book-1 {
      position: relative;

      //height: 42px;
      width: 150px;
      //margin-top: 15px;
      border: none;
      background-color: #1f6085;
      border-radius: 8px;
      font-size: 16px;
      color: #fff !important;
      cursor: pointer;
      text-decoration: none !important;
      font-weight: 500;
      padding: 7px 15px;
      a {
        text-decoration: none;
        color: #fff !important;
        font-weight: 600;
      }
    }
    p {
      line-height: 1.5;
    }
  }
}
@media screen and (max-width: 390px) {
  .booking {
    width: 100%;
    padding-top: 0px !important;
  }

  .container-b {
    display: flex;
    flex-direction: column;
    //position: absolute;
    bottom: 100px;
    width: 100%;

    .image {
      //background: url(../../assets/image/homepic.jpg)no-repeat;
      //background-position: center;
      //background-size: cover;
      width: 100%;
      height: 680px !important;
      object-fit: cover;
    }
    .detail {
      width: 100%;
      background-color: transparent !important ;
      padding-left: 40px !important;
      padding-top: 90px !important;
      height: 400px;
      position: absolute;
      top: 300px;
      h1 {
        width: 300px;
        font-size: 30px;
        margin-bottom: 20px;
        padding-top: 20px;
        color: #fff;
        margin-bottom: 7px;
      }
      .book-1 {
        position: relative;

        //height: 42px;
        width: 140px;
        //margin-top: 15px;
        border: none;
        background-color: #144866;
        border-radius: 6px;
        font-size: 16px;
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        font-weight: 500;
        padding: 7px 15px;
      }
      p {
        line-height: 1.5;
        color: #fff;
        font-size: 12px;
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .booking {
    width: 100%;
    padding-top: 0px !important;
  }

  .container-b {
    display: flex;
    flex-direction: column;
    //position: absolute;
    bottom: 100px;
    width: 100%;

    .image {
      //background: url(../../assets/image/homepic.jpg)no-repeat;
      //background-position: center;
      //background-size: cover;
      width: 100%;
      height: 680px !important;
      object-fit: cover;
    }
    .detail {
      width: 100%;
      background-color: transparent !important ;
      padding-left: 50px;
      padding-top: 100px;
      height: 400px;
      position: absolute;
      top: 300px;
      h1 {
        width: 300px;
        font-size: 30px;
        margin-bottom: 20px;
        padding-top: 20px;
        color: #fff;
        margin-bottom: 7px;
      }
      .book-1 {
        position: relative;

        //height: 42px;
        width: 140px;
        //margin-top: 15px;
        border: none;
        background-color: #144866;
        border-radius: 6px;
        font-size: 16px;
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        font-weight: 500;
        padding: 7px 15px;
      }
      p {
        line-height: 1.5;
        color: #fff;
        font-size: 12px;
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (min-width: 700px) and (max-width: 950px) {
  .booking {
    width: 100%;
    padding-top: 0px !important;
  }

  .container-b {
    display: flex;
    flex-direction: column;
    //position: absolute;
    bottom: 100px;
    width: 100%;

    .image {
      //background: url(../../assets/image/homepic.jpg)no-repeat;
      //background-position: center;
      //background-size: cover;
      width: 100%;
      height: 680px !important;
      object-fit: cover;
    }
    .detail {
      width: 100%;
      background-color: transparent !important ;
      padding-left: 50px;
      padding-top: 100px;
      height: 400px;
      position: absolute;
      top: 300px;
      h1 {
        width: 300px;
        font-size: 30px;
        margin-bottom: 20px;
        padding-top: 20px;
        color: #fff;
        margin-bottom: 7px;
      }
      .book-1 {
        position: relative;

        //height: 42px;
        width: 140px;
        //margin-top: 15px;
        border: none;
        background-color: #144866;
        border-radius: 6px;
        font-size: 16px;
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        font-weight: 500;
        padding: 7px 15px;
      }
      p {
        line-height: 1.5;
        color: #fff;
        font-size: 12px;
        margin-bottom: 20px;
      }
    }
  }
}
