

.modal {
 
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  right: 0px;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
  transition: opacity 0.15s linear;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-trans {
  background-color: rgba(0, 0, 0, 0.2);
}
.modal.open {
  left: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0rem;
  pointer-events: none;
  transform: none;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}
.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: none;
  border-radius: 0.3rem;
  outline: 0;
}
.room-pad{
    width: 60%;
    margin: auto;
    padding: 1rem 0;
}

.container-room-pop:hover {
  overflow-y: overlay;
  transition: 0.4s all ease;
}
.container-room-pop::-webkit-scrollbar {
  width: 0.5em;
}
.container-room-pop::-webkit-scrollbar:hover {
  display: block;
  transition: 0.4s all ease;
}
.container-room-pop::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.container-room-pop::-webkit-scrollbar-thumb {
  background-color: #aaa;
  outline: 1px solid slategrey;
  border-radius: 10px;
}
.container-room-pop {
    height: 95vh;
    width: 100%;
    margin: auto;
    overflow: hidden;
    padding: 30px;
    background-color: #ffff;
    border-radius: 6px;
    border:  1px solid #dadada;
    .room-stay{
        display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding-bottom: 20px;
    border-bottom:1px solid #f1f0f0 ;
    h6 {
        font-size: 24px;
        color: #2b2b2b;
        margin: 0;
      }
    }
    .room-image{
        img{
            background-position: center;
                        background-size: cover;
                        width: 100%;
                        height: 360px;
                        border-radius: 24px;
                        object-fit: cover;
        }
    }
    h4{
        font-size: 20px;
        margin: 10px;
    }
    .room-amenities{
        display: flex;
        flex-direction: column;
        .basic{
            width: 100%;
            h4{
                margin:10px ;
                font-weight: 500;
                color: #000;
                font-size: 16px;
            }
            .basic-list{
                display: flex;
                width: 100%;
                gap: 50px;
                ul{
                    margin: 0%;
                    color: #6e706e;
                    //width: 40%;
                }

            }
        }
        .paid{
            display: flex;
                width: 100%;
            h4{
                margin:10px ;
                font-weight: 400;
                color: #6e706e;
                font-size: 16px;
            }
            ul{
                margin: 0%;
                color: #6e706e;
            }

        }
    }
  }


@media (max-width: 450px) {
  .modal-dialog {
    padding-right: 0px !important;
  }
  .room-pad{
    width: 90%;
    margin: 180px auto auto auto ;
    padding: 1rem 0;
}



.container-room-pop {
    
    width: 100%;
    margin: auto;
    
    padding: 15px !important;
    background-color: #ffff;
    border-radius: 6px;
    border:  1px solid #dadada;
    .room-stay{
        display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding-bottom: 10px !important;
    border-bottom:1px solid #f1f0f0 ;
    h6 {
        font-size: 18px !important;
        color: #2b2b2b;
        margin: 0;
      }
    }
    .room-image{
        img{
            background-position: center;
                        background-size: cover;
                        width: 100% !important;
                        height: 200px !important;
                        border-radius: 24px;
                        object-fit: cover;
        }
    }
    h4{
        font-size: 15px !important;
        margin: 10px;
    }
    .room-amenities{
        display: flex;
        .basic{
            width: 55% !important;
            h4{
                margin:10px ;
                font-weight: 400;
                color: #6e706e;
                font-size: 12px !important;
            }
            .basic-list{
                display: flex;
                width: 100%;
                gap: 0px !important;
                ul{
                    margin: 0%;
                    color: #6e706e;
                    font-size: 10px !important;
                    padding-left: 30px;
                }

            }
        }
        .paid{
            
                width: 45% !important;
            h4{
                margin:10px ;
                font-weight: 400;
                color: #6e706e;
                font-size: 12px !important;
            }
            ul{
                margin: 0%;
                color: #6e706e;
                font-size: 10px !important;
                padding-left: 30px;
            }

        }
    }
  }
}

@media (min-width: 700px) and (max-width: 950px) {
  .modal-dialog {
    padding-right: 0px !important;
  }
  .room-pad{
    width: 90%;
    margin: 20px auto auto auto ;
    padding: 1rem 0;
}



.container-room-pop {
    
    width: 100%;
    margin: auto;
    height: 100%;
    padding: 15px !important;
    padding-bottom: 25px !important;
    background-color: #ffff;
    border-radius: 6px;
    border:  1px solid #dadada;
    .room-stay{
        display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding-bottom: 10px !important;
    border-bottom:1px solid #f1f0f0 ;
    h6 {
        font-size: 18px !important;
        color: #2b2b2b;
        margin: 0;
      }
    }
    .room-image{
        img{
            background-position: center;
                        background-size: cover;
                        width: 100% !important;
                        height: 280px !important;
                        border-radius: 24px;
                        object-fit: cover;
        }
    }
    h4{
        font-size: 18px !important;
        margin: 10px;
    }
    .room-amenities{
        display: flex;
        .basic{
            width: 55% !important;
            h4{
                margin:10px ;
                font-weight: 400;
                color: #6e706e;
                font-size: 14px !important;
            }
            .basic-list{
                display: flex;
                width: 100%;
                gap: 50px !important;
                ul{
                    margin: 0%;
                    color: #6e706e;
                    font-size: 12px !important;
                    padding-left: 30px;
                }

            }
        }
        .paid{
            
                width: 45% !important;
            h4{
                margin:10px ;
                font-weight: 400;
                color: #6e706e;
                font-size: 14px !important;
            }
            ul{
                margin: 0%;
                color: #6e706e;
                font-size: 12px !important;
                padding-left: 30px;
            }

        }
    }
  }
}




