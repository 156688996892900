

.container-s{
    width: 100%;
    //height: 680px !important;
    margin-right: auto;
    margin-left: auto;
    .container-slick-home{
        margin: 0 auto;
        width: 100% !important;
        .box-slick-1{
            display: flex !important;
            flex-direction: row;
            width: 100% !important;
            //margin-left: 10px;
            height: 100vh;
            //border-radius: 20px;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;
            object-fit: cover;
            margin-bottom: 60px; 
            .carousel-img-1{
                background-position: center;
                background-size: cover;
                height: 100vh;
                width: 100%;
            }
        }
        
    
      
    
        
    }
}
.content {
    text-align: center;
    margin-bottom: 50px;
    //height: 680px !important;
   
}




.slick-dots li button:before {
    font-size: medium;
}
.slick-next:before,
.slick-prev:before{
    background-color:rgb(239, 238, 238) ;
    font-size: 0px;
} 

@media screen and (max-width:390px){

    .container-s{
        width: 100% !important;
        
        margin-right: 0px;
        margin-left: 0px;
    }
    .content {
        text-align: center;
        margin-bottom: 50px;
    }
    
    .container-slick-home{
        margin: 0px !important;
       
        width: 100% !important;
    //     .slick-prev, 
    // .slick-next{
    //     display: none !important;
    // }
        .box-slick-1{
            display: flex !important;
            flex-direction: row;
            width: 100% !important;
            //margin-left: 10px;
            height: 680px !important;
            //border-radius: 20px;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;
            object-fit: cover;
            margin-bottom: 60px; 
            .carousel-img-1{
                background-position: center;
                background-size: cover;
                height: 680px !important;
                width: 100%;
            }
        }
        
    
        
     
        
    }

    
    
    .slick-track{
       
        margin-right: -20px !important;
    }
    .slick-dots li button:before {
        font-size: medium;
    }
    .slick-next:before,
    .slick-prev:before{
        background-color:rgb(239, 238, 238) ;
        font-size: 0px;
    } 
}

@media screen and (max-width:450px){

    .container-s{
        width: 100% !important;
        
        margin-right: 0px;
        margin-left: 0px;
    }
    .content {
        text-align: center;
        margin-bottom: 50px;
    }
    
    .container-slick-home{
        margin: 0px !important;
       
        width: 100% !important;
        .box-slick-1{
            display: flex !important;
            flex-direction: row;
            width: 100% !important;
            //margin-left: 10px;
            height: 680px !important;
            //border-radius: 20px;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;
            object-fit: cover;
            margin-bottom: 60px; 
            .carousel-img-1{
                background-position: center;
                background-size: cover;
                height: 680px !important;
                width: 100%;
            }
        }
        
    
        
     
        
    }

    // .slick-prev, 
    // .slick-next{
    //     display: none !important;
    // }
    
    .slick-track{
       
        margin-right: -20px !important;
    }
    .slick-dots li button:before {
        font-size: medium;
    }
    .slick-next:before,
    .slick-prev:before{
        background-color:rgb(239, 238, 238) ;
        font-size: 0px;
    } 
}


@media screen and (min-width: 700px) and (max-width: 850px){

    .container-s{
        width: 100% !important;
        
        margin-right: 0px;
        margin-left: 0px;
    }
    .content {
        text-align: center;
        margin-bottom: 50px;
    }
    
    .container-slick-home{
        margin: 0px !important;
       
        width: 100% !important;
        .box-slick-1{
            display: flex !important;
            flex-direction: row;
            width: 100% !important;
            //margin-left: 10px;
            height: 680px !important;
            //border-radius: 20px;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;
            object-fit: cover;
            margin-bottom: 60px; 
            .carousel-img-1{
                background-position: center;
                background-size: cover;
                height: 680px !important;
                width: 100%;
            }
        }
        
    
        
     
        
    }

    .slick-prev, 
    .slick-next{
        //display: none !important;
    }
    
    .slick-track{
       
        margin-right: -20px !important;
    }
    .slick-dots li button:before {
        font-size: medium;
    }
    .slick-next:before,
    .slick-prev:before{
        background-color:rgb(239, 238, 238) ;
        font-size: 0px;
    } 
}
