

.container-r{
    width: 100%;
    
    margin-right: auto;
    margin-left: auto;
}
.content-slick {
    text-align: center;
    margin-bottom: 50px;
}

.container-slick-2{
    margin: 0 auto;
    width: 100% !important;
    
    

   
    .comment-1{
        display: flex;
        flex-direction: column;
        scroll-snap-align: start;
        height: 315px;
        width: 95% !important;
        border-radius: 20px;
        align-items: start;
        justify-content: space-between;
        background-color:rgb(239, 238, 238);
        padding: 25px;
        margin-bottom: 60px;
        margin-left: 10px;

        .part-first-c{
            display: flex;
            flex-direction: column;
            justify-self: start !important;
            align-items: start;
            height: 83%;
            gap: 15px;
        .symbol{
            position: relative;
            width:70px;
            height: 70px;
            margin-bottom: 3px;
            top: -25px;
        }
        .head-1{
            font-size: 16px;
            width: 95%;
            line-height: 1.5;
            text-align: justify;
            margin-top: -53px;
        }
        }
        .part-second-c{
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: center;
            align-content: center;
            height: 20%;
            width: 100%;
        
        .profile-p{
            display: flex;
            align-items: center;            
            height: 90px;
            width: 13%;
            .p-image{
                display:flex;
                justify-content: start;
                width: 70%;
                height: 45px;
                background-color: dimgrey;
                border-radius: 50px;
            }

        }
        .name-tag{
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            height: 60px;
            align-items: start;
            width: 85%;
            .name-c{
                font-size: 23px;
                font-weight: 700;

            }
            .cust-c{
                font-size: 16px;
                color: gray;

            }
        }
    }
  }

    
}
@media screen and (min-width:574px){
.container-slick-3{
    display: none;
}
}

.slick-dots li button:before {
    font-size: medium;
}
.slick-next:before,
.slick-prev:before{
    background-color:rgb(239, 238, 238) ;
    font-size: 0px;
} 


@media screen and (max-width:390px){

    .container-r{
        width: 100% !important;
        
        margin-right: 0px;
        margin-left: 0px;
    }
    .content-slick {
        text-align: center;
        margin-bottom: 50px;
    }
    
    .container-slick-2{
        display: none;
    }
    
    .container-slick-3{
        margin: 0px !important;
        width: 100% !important;
        padding-bottom: 30px;
        .slick-list{
            width: 90vw !important;
            height: 264px !important ;
        }
       
    
       

        .slick-dots{
            width: 100%;
        }
        .comment-1{
            display: flex;
            flex-direction: column;
            scroll-snap-align: start;
            height: 255px !important;
            width: 100% !important;
            border-radius: 10px;
            align-items: start;
            justify-content: space-between;
            background-color:rgb(239, 238, 238);
            padding: 15px;
            margin-bottom: 20px;
            margin-left: 0px;
    
            .part-first-c{
                display: flex;
                flex-direction: column;
                justify-self: start !important;
                align-items: start;
                height: 60%;
                gap: 15px;
            .symbol{
                width:80px !important;
                height: 80px !important;
                margin-bottom: 0px !important;
                position:relative;
                top: -25px;
            }
            .head-1{
                font-size: 14px !important;
                width: 95%;
                line-height: 1.1;
                text-align: justify;
                margin-top: -60px;
            }
            }
            .part-second-c{
                display: flex;
                flex-direction: row;
                justify-content: start;
                align-items: center;
                align-content: center;
                height: 10%;
                width: 100%;
                margin-top: 70px !important; 
            .profile-p{
                display: flex;
                align-items: center;            
                height: 25px !important;
                width: 20% !important;
                .p-image{
                    display:flex;
                    justify-content: start;
                    width: 80%;
                    height: 25px !important;
                    background-color: dimgrey;
                    border-radius: 50px;
                }
    
            }
            .name-tag{
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                height: 30px;
                align-items: start;
                width: 85%;
                
                .name-c{
                    font-size: 10px;
                    font-weight: 700;
                    margin-bottom: 4px;
    
                }
                .cust-c{
                    font-size: 8px;
                    max-width: 100px;
                    color: gray;
    
                }
            }
        }
      }
    
        
    }
    .slick-dots li{
        width: 10px !important;
        height: 10px !important;
    }
    
    .slick-dots li button:before {
        font-size: 10px;
    }
    .slick-next:before,
    .slick-prev:before{
        background-color:rgb(239, 238, 238) ;
        font-size: 0px;
    } 
}

@media screen and (max-width:450px){

    .container-r{
        width: 100% !important;
        
        margin-right: 0px;
        margin-left: 0px;
    }
    .content-slick {
        text-align: center;
        margin-bottom: 50px;
    }

    .container-slick-2{
        display: none;
    }
    
    .container-slick-3{
        margin: 0px !important;
        width: 100% !important;
        padding-bottom: 30px;
        .slick-list{
            width: 90vw !important;
            height: 264px !important ;
        }
       
    
       

        .slick-dots{
            width: 100%;
        }
        .comment-1{
            display: flex;
            flex-direction: column;
            scroll-snap-align: start;
            height: 255px !important;
            width: 100% !important;
            border-radius: 10px;
            align-items: start;
            justify-content: space-between;
            background-color:rgb(239, 238, 238);
            padding: 15px;
            margin-bottom: 20px;
            margin-left: 0px;
    
            .part-first-c{
                display: flex;
                flex-direction: column;
                justify-self: start !important;
                align-items: start;
                height: 60%;
                gap: 15px;
            .symbol{
                width:80px !important;
                height: 80px !important;
                margin-bottom: 0px !important;
                position:relative;
                top: -25px;
            }
            .head-1{
                font-size: 14px !important;
                width: 95%;
                line-height: 1.1;
                text-align: justify;
                margin-top: -60px;
            }
            }
            .part-second-c{
                display: flex;
                flex-direction: row;
                justify-content: start;
                align-items: center;
                align-content: center;
                height: 10%;
                width: 100%;
                margin-top: 70px !important; 
            .profile-p{
                display: flex;
                align-items: center;            
                height: 25px !important;
                width: 20% !important;
                .p-image{
                    display:flex;
                    justify-content: start;
                    width: 80%;
                    height: 25px !important;
                    background-color: dimgrey;
                    border-radius: 50px;
                }
    
            }
            .name-tag{
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                height: 30px;
                align-items: start;
                width: 85%;
                
                .name-c{
                    font-size: 10px;
                    font-weight: 700;
                    margin-bottom: 4px;
    
                }
                .cust-c{
                    font-size: 8px;
                    max-width: 100px;
                    color: gray;
    
                }
            }
        }
      }
    
        
    }
    .slick-dots li{
        width: 10px !important;
        height: 10px !important;
    }
    
    .slick-dots li button:before {
        font-size: 10px;
    }
    .slick-next:before,
    .slick-prev:before{
        background-color:rgb(239, 238, 238) ;
        font-size: 0px;
    } 
}

@media screen and (min-width: 700px) and (max-width: 850px){

    .container-r{
        width: 100% !important;
        
        margin-right: 0px;
        margin-left: 0px;
    }
    .content-slick {
        text-align: center;
        margin-bottom: 50px;
    }
    
    .container-slick-2{
        margin: 0px !important;
        width: 100% !important;
        padding-bottom: 30px;
        .slick-list{
            width: 95vw;
            height:310px ;
        }
       
    
       

        .slick-dots{
            width: 100%;
        }
        .comment-1{
            display: flex;
            flex-direction: column;
            scroll-snap-align: start;
            height: 270px;
            width: 95% !important;
            border-radius: 10px;
            align-items: start;
            justify-content: space-between;
            background-color:rgb(239, 238, 238);
            padding: 10px;
            margin-bottom: 30px;
            margin-left: 0px;
    
            .part-first-c{
                display: flex;
                flex-direction: column;
                justify-self: start !important;
                align-items: start;
                height: 60%;
                gap: 15px;
            .symbol{
                width:60px !important;
                height: 60px !important;
                margin-bottom: 0px !important;
                position:relative;
                top: -10px;
            }
            .head-1{
                font-size: 14px;
                width: 95%;
                line-height: 1.3;
                text-align: justify;
                margin-top: -35px;
            }
            }
            .part-second-c{
                display: flex;
                flex-direction: row;
                justify-content: start;
                align-items: center;
                align-content: center;
                height: 10%;
                width: 100%;
                margin-top: 50px;
            .profile-p{
                display: flex;
                align-items: center;            
                height: 40px;
                width: 13%;
                .p-image{
                    display:flex;
                    justify-content: start;
                    width: 70%;
                    height: 30px;
                    background-color: dimgrey;
                    border-radius: 50px;
                }
    
            }
            .name-tag{
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                height: 30px;
                align-items: start;
                width: 85%;
                
                .name-c{
                    font-size: 12px;
                    font-weight: 700;
                    margin-bottom: 4px;
    
                }
                .cust-c{
                    font-size: 8px;
                    max-width: 100px;
                    color: gray;
    
                }
            }
        }
      }
    
        
    }
    .slick-dots li{
        width: 10px !important;
        height: 10px !important;
    }
    
    .slick-dots li button:before {
        font-size: 10px;
    }
    .slick-next:before,
    .slick-prev:before{
        background-color:rgb(239, 238, 238) ;
        font-size: 0px;
    } 
}