.face-slider {
    overflow: hidden;
    .slide-container::-webkit-scrollbar{
        display: none;
    }
    .slide-container {
      //position: relative;
      //display: flex;
     // flex-direction: row;
      //margin: 0 0 1.5rem 0;
      //padding: 0;
    
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: calc((100% / 2) - 0px);
      padding: 0px;
      gap: 25px;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      scrollbar-width: none;
  

      
      .message {
        display: flex;
        flex-direction: row;
        scroll-snap-align: start;
        height: 31vh;
        width: 95%;
        border-radius: 20px;
        align-items: center;
        justify-content: space-evenly;
        background-color: #fff;
        .part-first{
            width: 40%;

            .carousel-img{
                width: 190px;
                height: 190px;
                border-radius: 15px;
            }
        }
        .part-second{
            width: 50%;
            display: flex;
            flex-direction: column;
            text-align: center;
            .head-1{
                font-size: 35px;
                font-weight: 600;
            }
            .p-1{
                width: 85%;
                color: gray;
            }
             
             
            
            .book{
                height: 5vh;
                width: 50%;
                margin-top: 10px;
                border: none;
                background-color:#2B5399;
                border-radius: 40px;
                font-size: 16px;
                color:#fff;
                cursor: pointer;                              
            }
        }
      }

     
    }
    // Carousel indicators
    .c-indicators {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 1.5rem;
      padding: 0;
      margin: 0;
      list-style-type: none;
      
  
      li {
        &:nth-of-type(n + 2) {
          margin-left: 0.2rem;
        }
      }
  
      .c-indicator {
        display: block;
        width: 15px;
        height: 15px;
        border-radius: 50px;
        background-color: gray;
        margin-left: 30px;
        margin-top: 20px;
        
        
        
        cursor: pointer;
        transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);
        &:hover {
          opacity: 0.5;
        }
        &-active {
          &,
          &:hover {
            background-color: black;
          }
        }
      }
    }
  }
  