*{
    box-sizing: border-box;
}

.fixed-footer{
    
    bottom: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    padding: 20px 125px ;
    padding-bottom: 70px;
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.flex-group-one{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    width: 100%;
    .sub{
        width: 60%;
        h1{
        font-size: 50px;
        font-weight: 400;
        width: 100%;
        }
    }
    .s-button{
        width: 40%;
        position: relative;
        .email{
            position: relative;
            width: 450px;
            height: 70px;
            margin-top: 50px;
            border-radius: 50px;
            border: none;
            text-align: start;
            padding-left: 20px;
            font-size: 18px;
            background-color: rgb(241, 245, 248);
            color: #c8c8c8;
            
        }
        .start{
            position:relative;
            height:60px ;
            border-radius: 50px;
            border: none;
            text-align: center;
            width: 200px;
            font-size: 18px;
            background-color: #1f6085;
            color: #fff;
            left: 243px;
            bottom: 65px;
            
            
            
        }
    }
}
hr{
    border-color: #ededed;
}
.flex-group-two{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .content-one{
        padding-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        
         .logo-foot{
            width: 155px;
            height: 50px;
            a{
                text-decoration: none;
                color: #474747
                ;
                cursor: pointer;
                position: relative;
            
            .logo-head{
                width: 120px;
                height: 120px;
                position: absolute;
                top: -30px;
            }  
               
            }
        }
        p{
            line-height: 1.5;
            color: #474747;
            font-size: 20px;
        }
    }
    .content-two{
        padding-top: 20px;
        color:#474747 ;
        table{
            text-align: left;
            th,td{
                padding: 10px;
                padding-right: 30px;

            }
        }
    }
}
@media screen and (max-width:390px){
    .fixed-footer{
    
        bottom: 0;
        left: 0;
        background-color: #fff;
        width: 100%;
        padding: 15px 15px !important ;
        padding-bottom: 50px;
        padding-top: 50px !important;
    }
    .container{
        width: 100% !important;
    }
    .flex-group-one{
        width: 400px !important;
        .sub{
            width: 47% !important;
            h1{
                font-size: 16px ;
            }
        }
        .s-button{
            width: 52%;
            .email{
                position: relative;
                width: 100% !important;
                height: 36px !important;
                margin-top: 15px !important;
                border-radius: 50px;
                border: none;
                text-align: start;
                padding-left: 10px;
                font-size: 9px !important;
                background-color: rgb(241, 245, 248);
                color: #c8c8c8;
                
            }
            .start{
                position:absolute;
                height:30px !important ;
                border-radius: 50px;
                border: none;
                text-align: center;
                width: 63px !important;
                font-size: 8px;
                background-color: #1f6085;
                color: #fff;
                left: 113px;
                top: 18px;
                
                
                
            }
        }
    }
    .flex-group-two{
        display: flex;
        flex-direction: row;
        justify-content: flex-start !important;
        .content-one{
            padding-top: 10px;
            display: flex;
            justify-content: space-between !important;
            align-items: center;
            width: 100% !important;
             .logo-foot{
                width: 145px;
                height: 50px;
                a{
                    text-decoration: none;
                    color: #474747
                    ;
                    cursor: pointer;
                    position: relative;
                
                .logo-head{
                    width: 50px;
                    height: 50px;
                    position: absolute;
                    top: -5px;
                }  
                   
                }
            }
            p{
                line-height: 1.5;
                color: #474747;
                font-size: 8px !important;
            }
        }
        .content-two{
            padding-top: 20px;
            color:#474747 ;
            width: 400px ;
            p{
                line-height: 1.5;
                color: #474747;
                font-size: 11px !important;
            }
        }
    }


  }
@media screen and (max-width:450px){
    .fixed-footer{
    
        bottom: 0;
        left: 0;
        background-color: #fff;
        width: 100%;
        padding: 15px 15px !important ;
        padding-bottom: 50px;
        padding-top: 50px !important;
    }
    .container{
        width: 100% !important;
    }
    .flex-group-one{
        width: 100% !important;
        .sub{
            width: 47% !important;
            h1{
                font-size: 16px !important;
            }
        }
        .s-button{
            width: 52%;
            .email{
                position: relative;
                width: 101% !important;
                height: 36px !important;
                margin-top: 15px !important;
                border-radius: 50px;
                border: none;
                text-align: start;
                padding-left: 10px;
                font-size: 10px;
                background-color: rgb(241, 245, 248);
                color: #c8c8c8;
                
            }
            .start{
                position:absolute;
                height:30px !important ;
                border-radius: 50px;
                border: none;
                text-align: center;
                width: 65px;
                font-size: 8px;
                background-color: #144866;
                color: #fff;
                left: 120px;
                top: 18px;
                
                
                
            }
        }
    }
    .flex-group-two{
        display: flex;
        flex-direction: row;
        justify-content: flex-start !important;
        .content-one{
            padding-top: 10px;
            display: flex;
            justify-content: space-between !important;
            align-items: center;
            width: 400px !important;
             .logo-foot{
                width: 80px;
                height: 60px;
                a{
                    text-decoration: none;
                    color: #474747
                    ;
                    cursor: pointer;
                    position: relative;
                
                .logo-head{
                    width: 80px;
                    height: 80px;
                    position: absolute;
                    top: -10px !important;
                }  
                   
                }
            }
            p{
                line-height: 1.5;
                color: #474747;
                font-size: 11px;
            }
        }
        .content-two{
            padding-top: 20px;
            color:#474747 ;
            width: 400px ;
            p{
                line-height: 1.5;
                color: #474747;
                font-size: 12px ;
            }
        }
    }


  }

  @media screen and (min-width: 700px) and (max-width: 950px){
    .fixed-footer{
    
        bottom: 0;
        left: 0;
        background-color: #fff;
        width: 100%;
        padding: 15px 15px !important ;
        padding-bottom: 50px;
        padding-top: 50px !important;
    }
    .container{
        width: 100% !important;
    }
    .flex-group-one{
        width: 100% !important;
        .sub{
            width: 47% !important;
            h1{
                font-size: 16px !important;
            }
        }
        .s-button{
            width: 52%;
            .email{
                position: relative;
                width: 101% !important;
                height: 36px !important;
                margin-top: 15px !important;
                border-radius: 50px;
                border: none;
                text-align: start;
                padding-left: 10px;
                font-size: 10px;
                background-color: rgb(241, 245, 248);
                color: #c8c8c8;
                
            }
            .start{
                position:absolute;
                height:30px !important ;
                border-radius: 50px;
                border: none;
                text-align: center;
                width: 65px;
                font-size: 8px;
                background-color: #144866;
                color: #fff;
                left: 120px;
                top: 18px;
                
                
                
            }
        }
    }
    .flex-group-two{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 97%;
        .content-one{
            padding-top: 10px;
            display: flex;
            justify-content: space-between !important;
            align-items: center;
            //width: 400px !important;
             .logo-foot{
                width: 245px;
                height: 80px;
                a{
                    text-decoration: none;
                    color: #474747
                    ;
                    cursor: pointer;
                    position: relative;
                
                .logo-head{
                    width: 100px;
                    height: 100px;
                    position: absolute;
                    top: -5px;
                }  
                   
                }
            }
            p{
                line-height: 1.5;
                color: #474747;
                font-size: 14px;
            }
        }
        .content-two{
            padding-top: 20px;
            color:#474747 ;
            width: 400px ;
            p{
                line-height: 1.5;
                color: #474747;
                font-size: 12px ;
            }
        }
    }


  }

@media screen and (max-width:992px){

    .container{
        max-width: 960px;
    }
    .flex-group-one{
        .sub{
            h1{
                font-size: 40px;
            }
        }
    }


  }
  @media screen and (min-width:1100px){

    .container{
        max-width: 1040px;
    }
    .flex-group-one{
        .sub{
            h1{
                font-size: 45px;
            }
        }
    }


  }
    
  

@media screen and (min-width:1200px){
    .container{
        max-width: 1140px;
    }
    .flex-group-one{
        .s-button{
            .email{
                right: 80px !important;
            }
            
            .start{
                left: 163px !important;
            }
        }
    }
   
    }

@media screen and (min-width:1300px){
        .container{
            max-width: 1140px;
        }
        .flex-group-one{
            .s-button{
                .email{
                    right: 40px !important;
                }
                .start{
                    left: 203px !important;
                }
            }
        }
    }

@media screen and (min-width:1300px){
    .container{
         max-width: 1140px;
        }
    .flex-group-one{
        .s-button{
            .email{
                right: 0px !important;
                }
            .start{
                left: 243px !important;
                }
            }
         }
    }
    