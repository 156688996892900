
*{
    box-sizing: border-box;
}
.booking{
    width: 100%;
    padding-top: 80px;
}

.container-b{
    
    display: flex;
    flex-direction: row;
    
    .image{
    //background: url(../../assets/image/homepic.jpg)no-repeat;
    
      //width: 50%; 
      //height: 75vh; 
    }
    .detail{
        width: 50%;
        background-color: gainsboro;
        padding-left: 70px;
        padding-top: 165px;
        height: 75vh; 
        h1{
            width: 70%;
            font-size: 60px;
            margin-bottom: 0;
        }
        p{
            
            line-height: 1.5;
        }
        
        
    }
}
.container-out{
    padding: 50px 125px;
.container{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;


.card{
    display: flex;
    width: 100%;
    padding-left: 40px;
    border-radius:60px;
    box-shadow: 0 0.5rem 1.25rem 0 rgba(182,181,181,0.14);
    background-color: #fff;
    flex-direction: row;
    justify-content: space-between;
    z-index: 4;
    position: relative;
    height: 14vh;
    
    
    .search{
        padding: 20px;
        padding-top: 20px;
        align-items: center;
        align-content: center;
        font-size: 28px;
        border-radius: 60px;
        color:#fff;
        background-color: #2B5399;
        border: none;
        cursor: pointer;
        height: 14vh;
        width: 18%;
    }
    
    .content-1,
    .content-2,
    .content-3,
    .content-4{
        display: flex;
        flex-direction: column;
        width: 19%;
        padding-top: 25px;
        .label-input{
            text-align: left;
            padding-bottom: 5px;
            padding-left: 10px;
            font-size: 20px;
            color:rgb(51, 50, 50);
        }

        .from-control{
            display: block;
            width: 100%;
            height: calc(1em + 1rem + 4px);
            padding: 1rem 0.5rem;
            font-size: 20px;
            font-weight: 400;
            line-height: 1.5;
            color: #000;
            outline: none;
            border: none;
            align-items: center;
            
           
        }
       }
}
.vl {
    border-left: 2px solid #99999b;
    margin-top: 30px;
    height: 60px;
}


}
}